import JSONAPICache from '@ember-data/json-api';
import { LegacyNetworkHandler, adapterFor, serializerFor, pushPayload, normalize, serializeRecord, cleanup } from '@ember-data/legacy-compat';
import { buildSchema, instantiateRecord, teardownRecord, modelFor } from '@ember-data/model/hooks';
import RequestManager from '@ember-data/request';
import Fetch from '@ember-data/request/fetch';
import BaseStore, { CacheHandler } from '@ember-data/store';
function hasRequestManager(store) {
  return 'requestManager' in store;
}
class Store extends BaseStore {
  constructor(args) {
    super(args);
    if (!hasRequestManager(this)) {
      this.requestManager = new RequestManager();
      this.requestManager.use([LegacyNetworkHandler, Fetch]);
    }
    this.requestManager.useCache(CacheHandler);
  }
  createSchemaService() {
    return buildSchema(this);
  }
  createCache(storeWrapper) {
    return new JSONAPICache(storeWrapper);
  }
  instantiateRecord(identifier, createRecordArgs) {
    return instantiateRecord.call(this, identifier, createRecordArgs);
  }
  teardownRecord(record) {
    teardownRecord.call(this, record);
  }
  modelFor(type) {
    return modelFor.call(this, type) || super.modelFor(type);
  }
  adapterFor = adapterFor;
  serializerFor = serializerFor;
  pushPayload = pushPayload;
  normalize = normalize;
  serializeRecord = serializeRecord;
  destroy() {
    cleanup.call(this);
    super.destroy();
  }
}
export { Store as default };