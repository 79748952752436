/**
* @vue/shared v3.4.27
* (c) 2018-present Yuxi (Evan) You and Vue contributors
* @license MIT
**/
/*! #__NO_SIDE_EFFECTS__ */
// @__NO_SIDE_EFFECTS__
function ys(e, t) {
  const s = new Set(e.split(","));
  return t ? (n) => s.has(n.toLowerCase()) : (n) => s.has(n);
}
const U = {}, ke = [], oe = () => {
}, Rr = () => !1, jt = (e) => e.charCodeAt(0) === 111 && e.charCodeAt(1) === 110 && // uppercase letter
(e.charCodeAt(2) > 122 || e.charCodeAt(2) < 97), xs = (e) => e.startsWith("onUpdate:"), V = Object.assign, ws = (e, t) => {
  const s = e.indexOf(t);
  s > -1 && e.splice(s, 1);
}, Ir = Object.prototype.hasOwnProperty, I = (e, t) => Ir.call(e, t), P = Array.isArray, ze = (e) => Lt(e) === "[object Map]", Sn = (e) => Lt(e) === "[object Set]", A = (e) => typeof e == "function", q = (e) => typeof e == "string", Be = (e) => typeof e == "symbol", B = (e) => e !== null && typeof e == "object", Tn = (e) => (B(e) || A(e)) && A(e.then) && A(e.catch), An = Object.prototype.toString, Lt = (e) => An.call(e), Mr = (e) => Lt(e).slice(8, -1), Rn = (e) => Lt(e) === "[object Object]", vs = (e) => q(e) && e !== "NaN" && e[0] !== "-" && "" + parseInt(e, 10) === e, tt = /* @__PURE__ */ ys(
  // the leading comma is intentional so empty string "" is also included
  ",key,ref,ref_for,ref_key,onVnodeBeforeMount,onVnodeMounted,onVnodeBeforeUpdate,onVnodeUpdated,onVnodeBeforeUnmount,onVnodeUnmounted"
), Ht = (e) => {
  const t = /* @__PURE__ */ Object.create(null);
  return (s) => t[s] || (t[s] = e(s));
}, Nr = /-(\w)/g, we = Ht((e) => e.replace(Nr, (t, s) => s ? s.toUpperCase() : "")), Fr = /\B([A-Z])/g, ie = Ht(
  (e) => e.replace(Fr, "-$1").toLowerCase()
), In = Ht((e) => e.charAt(0).toUpperCase() + e.slice(1)), Yt = Ht((e) => e ? `on${In(e)}` : ""), Ie = (e, t) => !Object.is(e, t), Qt = (e, t) => {
  for (let s = 0; s < e.length; s++)
    e[s](t);
}, Mn = (e, t, s, n = !1) => {
  Object.defineProperty(e, t, {
    configurable: !0,
    enumerable: !1,
    writable: n,
    value: s
  });
}, $r = (e) => {
  const t = parseFloat(e);
  return isNaN(t) ? e : t;
}, zs = (e) => {
  const t = q(e) ? Number(e) : NaN;
  return isNaN(t) ? e : t;
};
let Gs;
const Nn = () => Gs || (Gs = typeof globalThis < "u" ? globalThis : typeof self < "u" ? self : typeof window < "u" ? window : typeof global < "u" ? global : {});
function Cs(e) {
  if (P(e)) {
    const t = {};
    for (let s = 0; s < e.length; s++) {
      const n = e[s], r = q(n) ? Kr(n) : Cs(n);
      if (r)
        for (const i in r)
          t[i] = r[i];
    }
    return t;
  } else if (q(e) || B(e))
    return e;
}
const jr = /;(?![^(]*\))/g, Lr = /:([^]+)/, Hr = /\/\*[^]*?\*\//g;
function Kr(e) {
  const t = {};
  return e.replace(Hr, "").split(jr).forEach((s) => {
    if (s) {
      const n = s.split(Lr);
      n.length > 1 && (t[n[0].trim()] = n[1].trim());
    }
  }), t;
}
function lt(e) {
  let t = "";
  if (q(e))
    t = e;
  else if (P(e))
    for (let s = 0; s < e.length; s++) {
      const n = lt(e[s]);
      n && (t += n + " ");
    }
  else if (B(e))
    for (const s in e)
      e[s] && (t += s + " ");
  return t.trim();
}
const Ur = "itemscope,allowfullscreen,formnovalidate,ismap,nomodule,novalidate,readonly", Dr = /* @__PURE__ */ ys(Ur);
function Fn(e) {
  return !!e || e === "";
}
const Br = (e) => q(e) ? e : e == null ? "" : P(e) || B(e) && (e.toString === An || !A(e.toString)) ? JSON.stringify(e, $n, 2) : String(e), $n = (e, t) => t && t.__v_isRef ? $n(e, t.value) : ze(t) ? {
  [`Map(${t.size})`]: [...t.entries()].reduce(
    (s, [n, r], i) => (s[Xt(n, i) + " =>"] = r, s),
    {}
  )
} : Sn(t) ? {
  [`Set(${t.size})`]: [...t.values()].map((s) => Xt(s))
} : Be(t) ? Xt(t) : B(t) && !P(t) && !Rn(t) ? String(t) : t, Xt = (e, t = "") => {
  var s;
  return (
    // Symbol.description in es2019+ so we need to cast here to pass
    // the lib: es2016 check
    Be(e) ? `Symbol(${(s = e.description) != null ? s : t})` : e
  );
};
/**
* @vue/reactivity v3.4.27
* (c) 2018-present Yuxi (Evan) You and Vue contributors
* @license MIT
**/
let ce;
class Vr {
  constructor(t = !1) {
    this.detached = t, this._active = !0, this.effects = [], this.cleanups = [], this.parent = ce, !t && ce && (this.index = (ce.scopes || (ce.scopes = [])).push(
      this
    ) - 1);
  }
  get active() {
    return this._active;
  }
  run(t) {
    if (this._active) {
      const s = ce;
      try {
        return ce = this, t();
      } finally {
        ce = s;
      }
    }
  }
  /**
   * This should only be called on non-detached scopes
   * @internal
   */
  on() {
    ce = this;
  }
  /**
   * This should only be called on non-detached scopes
   * @internal
   */
  off() {
    ce = this.parent;
  }
  stop(t) {
    if (this._active) {
      let s, n;
      for (s = 0, n = this.effects.length; s < n; s++)
        this.effects[s].stop();
      for (s = 0, n = this.cleanups.length; s < n; s++)
        this.cleanups[s]();
      if (this.scopes)
        for (s = 0, n = this.scopes.length; s < n; s++)
          this.scopes[s].stop(!0);
      if (!this.detached && this.parent && !t) {
        const r = this.parent.scopes.pop();
        r && r !== this && (this.parent.scopes[this.index] = r, r.index = this.index);
      }
      this.parent = void 0, this._active = !1;
    }
  }
}
function qr(e, t = ce) {
  t && t.active && t.effects.push(e);
}
function Wr() {
  return ce;
}
let Ue;
class Es {
  constructor(t, s, n, r) {
    this.fn = t, this.trigger = s, this.scheduler = n, this.active = !0, this.deps = [], this._dirtyLevel = 4, this._trackId = 0, this._runnings = 0, this._shouldSchedule = !1, this._depsLength = 0, qr(this, r);
  }
  get dirty() {
    if (this._dirtyLevel === 2 || this._dirtyLevel === 3) {
      this._dirtyLevel = 1, Me();
      for (let t = 0; t < this._depsLength; t++) {
        const s = this.deps[t];
        if (s.computed && (kr(s.computed), this._dirtyLevel >= 4))
          break;
      }
      this._dirtyLevel === 1 && (this._dirtyLevel = 0), Ne();
    }
    return this._dirtyLevel >= 4;
  }
  set dirty(t) {
    this._dirtyLevel = t ? 4 : 0;
  }
  run() {
    if (this._dirtyLevel = 0, !this.active)
      return this.fn();
    let t = Ae, s = Ue;
    try {
      return Ae = !0, Ue = this, this._runnings++, Js(this), this.fn();
    } finally {
      Ys(this), this._runnings--, Ue = s, Ae = t;
    }
  }
  stop() {
    this.active && (Js(this), Ys(this), this.onStop && this.onStop(), this.active = !1);
  }
}
function kr(e) {
  return e.value;
}
function Js(e) {
  e._trackId++, e._depsLength = 0;
}
function Ys(e) {
  if (e.deps.length > e._depsLength) {
    for (let t = e._depsLength; t < e.deps.length; t++)
      jn(e.deps[t], e);
    e.deps.length = e._depsLength;
  }
}
function jn(e, t) {
  const s = e.get(t);
  s !== void 0 && t._trackId !== s && (e.delete(t), e.size === 0 && e.cleanup());
}
let Ae = !0, ls = 0;
const Ln = [];
function Me() {
  Ln.push(Ae), Ae = !1;
}
function Ne() {
  const e = Ln.pop();
  Ae = e === void 0 ? !0 : e;
}
function Os() {
  ls++;
}
function Ps() {
  for (ls--; !ls && cs.length; )
    cs.shift()();
}
function Hn(e, t, s) {
  if (t.get(e) !== e._trackId) {
    t.set(e, e._trackId);
    const n = e.deps[e._depsLength];
    n !== t ? (n && jn(n, e), e.deps[e._depsLength++] = t) : e._depsLength++;
  }
}
const cs = [];
function Kn(e, t, s) {
  Os();
  for (const n of e.keys()) {
    let r;
    n._dirtyLevel < t && (r ?? (r = e.get(n) === n._trackId)) && (n._shouldSchedule || (n._shouldSchedule = n._dirtyLevel === 0), n._dirtyLevel = t), n._shouldSchedule && (r ?? (r = e.get(n) === n._trackId)) && (n.trigger(), (!n._runnings || n.allowRecurse) && n._dirtyLevel !== 2 && (n._shouldSchedule = !1, n.scheduler && cs.push(n.scheduler)));
  }
  Ps();
}
const Un = (e, t) => {
  const s = /* @__PURE__ */ new Map();
  return s.cleanup = e, s.computed = t, s;
}, fs = /* @__PURE__ */ new WeakMap(), De = Symbol(""), us = Symbol("");
function ee(e, t, s) {
  if (Ae && Ue) {
    let n = fs.get(e);
    n || fs.set(e, n = /* @__PURE__ */ new Map());
    let r = n.get(s);
    r || n.set(s, r = Un(() => n.delete(s))), Hn(
      Ue,
      r
    );
  }
}
function ve(e, t, s, n, r, i) {
  const o = fs.get(e);
  if (!o)
    return;
  let c = [];
  if (t === "clear")
    c = [...o.values()];
  else if (s === "length" && P(e)) {
    const u = Number(n);
    o.forEach((a, d) => {
      (d === "length" || !Be(d) && d >= u) && c.push(a);
    });
  } else
    switch (s !== void 0 && c.push(o.get(s)), t) {
      case "add":
        P(e) ? vs(s) && c.push(o.get("length")) : (c.push(o.get(De)), ze(e) && c.push(o.get(us)));
        break;
      case "delete":
        P(e) || (c.push(o.get(De)), ze(e) && c.push(o.get(us)));
        break;
      case "set":
        ze(e) && c.push(o.get(De));
        break;
    }
  Os();
  for (const u of c)
    u && Kn(
      u,
      4
    );
  Ps();
}
const zr = /* @__PURE__ */ ys("__proto__,__v_isRef,__isVue"), Dn = new Set(
  /* @__PURE__ */ Object.getOwnPropertyNames(Symbol).filter((e) => e !== "arguments" && e !== "caller").map((e) => Symbol[e]).filter(Be)
), Qs = /* @__PURE__ */ Gr();
function Gr() {
  const e = {};
  return ["includes", "indexOf", "lastIndexOf"].forEach((t) => {
    e[t] = function(...s) {
      const n = F(this);
      for (let i = 0, o = this.length; i < o; i++)
        ee(n, "get", i + "");
      const r = n[t](...s);
      return r === -1 || r === !1 ? n[t](...s.map(F)) : r;
    };
  }), ["push", "pop", "shift", "unshift", "splice"].forEach((t) => {
    e[t] = function(...s) {
      Me(), Os();
      const n = F(this)[t].apply(this, s);
      return Ps(), Ne(), n;
    };
  }), e;
}
function Jr(e) {
  Be(e) || (e = String(e));
  const t = F(this);
  return ee(t, "has", e), t.hasOwnProperty(e);
}
class Bn {
  constructor(t = !1, s = !1) {
    this._isReadonly = t, this._isShallow = s;
  }
  get(t, s, n) {
    const r = this._isReadonly, i = this._isShallow;
    if (s === "__v_isReactive")
      return !r;
    if (s === "__v_isReadonly")
      return r;
    if (s === "__v_isShallow")
      return i;
    if (s === "__v_raw")
      return n === (r ? i ? ci : kn : i ? Wn : qn).get(t) || // receiver is not the reactive proxy, but has the same prototype
      // this means the reciever is a user proxy of the reactive proxy
      Object.getPrototypeOf(t) === Object.getPrototypeOf(n) ? t : void 0;
    const o = P(t);
    if (!r) {
      if (o && I(Qs, s))
        return Reflect.get(Qs, s, n);
      if (s === "hasOwnProperty")
        return Jr;
    }
    const c = Reflect.get(t, s, n);
    return (Be(s) ? Dn.has(s) : zr(s)) || (r || ee(t, "get", s), i) ? c : te(c) ? o && vs(s) ? c : c.value : B(c) ? r ? zn(c) : As(c) : c;
  }
}
class Vn extends Bn {
  constructor(t = !1) {
    super(!1, t);
  }
  set(t, s, n, r) {
    let i = t[s];
    if (!this._isShallow) {
      const u = ct(i);
      if (!Mt(n) && !ct(n) && (i = F(i), n = F(n)), !P(t) && te(i) && !te(n))
        return u ? !1 : (i.value = n, !0);
    }
    const o = P(t) && vs(s) ? Number(s) < t.length : I(t, s), c = Reflect.set(t, s, n, r);
    return t === F(r) && (o ? Ie(n, i) && ve(t, "set", s, n) : ve(t, "add", s, n)), c;
  }
  deleteProperty(t, s) {
    const n = I(t, s);
    t[s];
    const r = Reflect.deleteProperty(t, s);
    return r && n && ve(t, "delete", s, void 0), r;
  }
  has(t, s) {
    const n = Reflect.has(t, s);
    return (!Be(s) || !Dn.has(s)) && ee(t, "has", s), n;
  }
  ownKeys(t) {
    return ee(
      t,
      "iterate",
      P(t) ? "length" : De
    ), Reflect.ownKeys(t);
  }
}
class Yr extends Bn {
  constructor(t = !1) {
    super(!0, t);
  }
  set(t, s) {
    return !0;
  }
  deleteProperty(t, s) {
    return !0;
  }
}
const Qr = /* @__PURE__ */ new Vn(), Xr = /* @__PURE__ */ new Yr(), Zr = /* @__PURE__ */ new Vn(
  !0
);
const Ss = (e) => e, Kt = (e) => Reflect.getPrototypeOf(e);
function yt(e, t, s = !1, n = !1) {
  e = e.__v_raw;
  const r = F(e), i = F(t);
  s || (Ie(t, i) && ee(r, "get", t), ee(r, "get", i));
  const { has: o } = Kt(r), c = n ? Ss : s ? Is : ft;
  if (o.call(r, t))
    return c(e.get(t));
  if (o.call(r, i))
    return c(e.get(i));
  e !== r && e.get(t);
}
function xt(e, t = !1) {
  const s = this.__v_raw, n = F(s), r = F(e);
  return t || (Ie(e, r) && ee(n, "has", e), ee(n, "has", r)), e === r ? s.has(e) : s.has(e) || s.has(r);
}
function wt(e, t = !1) {
  return e = e.__v_raw, !t && ee(F(e), "iterate", De), Reflect.get(e, "size", e);
}
function Xs(e) {
  e = F(e);
  const t = F(this);
  return Kt(t).has.call(t, e) || (t.add(e), ve(t, "add", e, e)), this;
}
function Zs(e, t) {
  t = F(t);
  const s = F(this), { has: n, get: r } = Kt(s);
  let i = n.call(s, e);
  i || (e = F(e), i = n.call(s, e));
  const o = r.call(s, e);
  return s.set(e, t), i ? Ie(t, o) && ve(s, "set", e, t) : ve(s, "add", e, t), this;
}
function en(e) {
  const t = F(this), { has: s, get: n } = Kt(t);
  let r = s.call(t, e);
  r || (e = F(e), r = s.call(t, e)), n && n.call(t, e);
  const i = t.delete(e);
  return r && ve(t, "delete", e, void 0), i;
}
function tn() {
  const e = F(this), t = e.size !== 0, s = e.clear();
  return t && ve(e, "clear", void 0, void 0), s;
}
function vt(e, t) {
  return function(n, r) {
    const i = this, o = i.__v_raw, c = F(o), u = t ? Ss : e ? Is : ft;
    return !e && ee(c, "iterate", De), o.forEach((a, d) => n.call(r, u(a), u(d), i));
  };
}
function Ct(e, t, s) {
  return function(...n) {
    const r = this.__v_raw, i = F(r), o = ze(i), c = e === "entries" || e === Symbol.iterator && o, u = e === "keys" && o, a = r[e](...n), d = s ? Ss : t ? Is : ft;
    return !t && ee(
      i,
      "iterate",
      u ? us : De
    ), {
      // iterator protocol
      next() {
        const { value: y, done: C } = a.next();
        return C ? { value: y, done: C } : {
          value: c ? [d(y[0]), d(y[1])] : d(y),
          done: C
        };
      },
      // iterable protocol
      [Symbol.iterator]() {
        return this;
      }
    };
  };
}
function Oe(e) {
  return function(...t) {
    return e === "delete" ? !1 : e === "clear" ? void 0 : this;
  };
}
function ei() {
  const e = {
    get(i) {
      return yt(this, i);
    },
    get size() {
      return wt(this);
    },
    has: xt,
    add: Xs,
    set: Zs,
    delete: en,
    clear: tn,
    forEach: vt(!1, !1)
  }, t = {
    get(i) {
      return yt(this, i, !1, !0);
    },
    get size() {
      return wt(this);
    },
    has: xt,
    add: Xs,
    set: Zs,
    delete: en,
    clear: tn,
    forEach: vt(!1, !0)
  }, s = {
    get(i) {
      return yt(this, i, !0);
    },
    get size() {
      return wt(this, !0);
    },
    has(i) {
      return xt.call(this, i, !0);
    },
    add: Oe("add"),
    set: Oe("set"),
    delete: Oe("delete"),
    clear: Oe("clear"),
    forEach: vt(!0, !1)
  }, n = {
    get(i) {
      return yt(this, i, !0, !0);
    },
    get size() {
      return wt(this, !0);
    },
    has(i) {
      return xt.call(this, i, !0);
    },
    add: Oe("add"),
    set: Oe("set"),
    delete: Oe("delete"),
    clear: Oe("clear"),
    forEach: vt(!0, !0)
  };
  return [
    "keys",
    "values",
    "entries",
    Symbol.iterator
  ].forEach((i) => {
    e[i] = Ct(i, !1, !1), s[i] = Ct(i, !0, !1), t[i] = Ct(i, !1, !0), n[i] = Ct(
      i,
      !0,
      !0
    );
  }), [
    e,
    s,
    t,
    n
  ];
}
const [
  ti,
  si,
  ni,
  ri
] = /* @__PURE__ */ ei();
function Ts(e, t) {
  const s = t ? e ? ri : ni : e ? si : ti;
  return (n, r, i) => r === "__v_isReactive" ? !e : r === "__v_isReadonly" ? e : r === "__v_raw" ? n : Reflect.get(
    I(s, r) && r in n ? s : n,
    r,
    i
  );
}
const ii = {
  get: /* @__PURE__ */ Ts(!1, !1)
}, oi = {
  get: /* @__PURE__ */ Ts(!1, !0)
}, li = {
  get: /* @__PURE__ */ Ts(!0, !1)
};
const qn = /* @__PURE__ */ new WeakMap(), Wn = /* @__PURE__ */ new WeakMap(), kn = /* @__PURE__ */ new WeakMap(), ci = /* @__PURE__ */ new WeakMap();
function fi(e) {
  switch (e) {
    case "Object":
    case "Array":
      return 1;
    case "Map":
    case "Set":
    case "WeakMap":
    case "WeakSet":
      return 2;
    default:
      return 0;
  }
}
function ui(e) {
  return e.__v_skip || !Object.isExtensible(e) ? 0 : fi(Mr(e));
}
function As(e) {
  return ct(e) ? e : Rs(
    e,
    !1,
    Qr,
    ii,
    qn
  );
}
function ai(e) {
  return Rs(
    e,
    !1,
    Zr,
    oi,
    Wn
  );
}
function zn(e) {
  return Rs(
    e,
    !0,
    Xr,
    li,
    kn
  );
}
function Rs(e, t, s, n, r) {
  if (!B(e) || e.__v_raw && !(t && e.__v_isReactive))
    return e;
  const i = r.get(e);
  if (i)
    return i;
  const o = ui(e);
  if (o === 0)
    return e;
  const c = new Proxy(
    e,
    o === 2 ? n : s
  );
  return r.set(e, c), c;
}
function st(e) {
  return ct(e) ? st(e.__v_raw) : !!(e && e.__v_isReactive);
}
function ct(e) {
  return !!(e && e.__v_isReadonly);
}
function Mt(e) {
  return !!(e && e.__v_isShallow);
}
function Gn(e) {
  return e ? !!e.__v_raw : !1;
}
function F(e) {
  const t = e && e.__v_raw;
  return t ? F(t) : e;
}
function hi(e) {
  return Object.isExtensible(e) && Mn(e, "__v_skip", !0), e;
}
const ft = (e) => B(e) ? As(e) : e, Is = (e) => B(e) ? zn(e) : e;
class Jn {
  constructor(t, s, n, r) {
    this.getter = t, this._setter = s, this.dep = void 0, this.__v_isRef = !0, this.__v_isReadonly = !1, this.effect = new Es(
      () => t(this._value),
      () => Pt(
        this,
        this.effect._dirtyLevel === 2 ? 2 : 3
      )
    ), this.effect.computed = this, this.effect.active = this._cacheable = !r, this.__v_isReadonly = n;
  }
  get value() {
    const t = F(this);
    return (!t._cacheable || t.effect.dirty) && Ie(t._value, t._value = t.effect.run()) && Pt(t, 4), Yn(t), t.effect._dirtyLevel >= 2 && Pt(t, 2), t._value;
  }
  set value(t) {
    this._setter(t);
  }
  // #region polyfill _dirty for backward compatibility third party code for Vue <= 3.3.x
  get _dirty() {
    return this.effect.dirty;
  }
  set _dirty(t) {
    this.effect.dirty = t;
  }
  // #endregion
}
function di(e, t, s = !1) {
  let n, r;
  const i = A(e);
  return i ? (n = e, r = oe) : (n = e.get, r = e.set), new Jn(n, r, i || !r, s);
}
function Yn(e) {
  var t;
  Ae && Ue && (e = F(e), Hn(
    Ue,
    (t = e.dep) != null ? t : e.dep = Un(
      () => e.dep = void 0,
      e instanceof Jn ? e : void 0
    )
  ));
}
function Pt(e, t = 4, s) {
  e = F(e);
  const n = e.dep;
  n && Kn(
    n,
    t
  );
}
function te(e) {
  return !!(e && e.__v_isRef === !0);
}
function pi(e) {
  return Qn(e, !1);
}
function gi(e) {
  return Qn(e, !0);
}
function Qn(e, t) {
  return te(e) ? e : new _i(e, t);
}
class _i {
  constructor(t, s) {
    this.__v_isShallow = s, this.dep = void 0, this.__v_isRef = !0, this._rawValue = s ? t : F(t), this._value = s ? t : ft(t);
  }
  get value() {
    return Yn(this), this._value;
  }
  set value(t) {
    const s = this.__v_isShallow || Mt(t) || ct(t);
    t = s ? t : F(t), Ie(t, this._rawValue) && (this._rawValue = t, this._value = s ? t : ft(t), Pt(this, 4));
  }
}
function Ze(e) {
  return te(e) ? e.value : e;
}
const mi = {
  get: (e, t, s) => Ze(Reflect.get(e, t, s)),
  set: (e, t, s, n) => {
    const r = e[t];
    return te(r) && !te(s) ? (r.value = s, !0) : Reflect.set(e, t, s, n);
  }
};
function Xn(e) {
  return st(e) ? e : new Proxy(e, mi);
}
/**
* @vue/runtime-core v3.4.27
* (c) 2018-present Yuxi (Evan) You and Vue contributors
* @license MIT
**/
function Re(e, t, s, n) {
  try {
    return n ? e(...n) : e();
  } catch (r) {
    Ut(r, t, s);
  }
}
function ue(e, t, s, n) {
  if (A(e)) {
    const r = Re(e, t, s, n);
    return r && Tn(r) && r.catch((i) => {
      Ut(i, t, s);
    }), r;
  }
  if (P(e)) {
    const r = [];
    for (let i = 0; i < e.length; i++)
      r.push(ue(e[i], t, s, n));
    return r;
  }
}
function Ut(e, t, s, n = !0) {
  const r = t ? t.vnode : null;
  if (t) {
    let i = t.parent;
    const o = t.proxy, c = `https://vuejs.org/error-reference/#runtime-${s}`;
    for (; i; ) {
      const a = i.ec;
      if (a) {
        for (let d = 0; d < a.length; d++)
          if (a[d](e, o, c) === !1)
            return;
      }
      i = i.parent;
    }
    const u = t.appContext.config.errorHandler;
    if (u) {
      Me(), Re(
        u,
        null,
        10,
        [e, o, c]
      ), Ne();
      return;
    }
  }
  bi(e, s, r, n);
}
function bi(e, t, s, n = !0) {
  console.error(e);
}
let ut = !1, as = !1;
const J = [];
let me = 0;
const Ge = [];
let Pe = null, Ke = 0;
const Zn = /* @__PURE__ */ Promise.resolve();
let Ms = null;
function er(e) {
  const t = Ms || Zn;
  return e ? t.then(this ? e.bind(this) : e) : t;
}
function yi(e) {
  let t = me + 1, s = J.length;
  for (; t < s; ) {
    const n = t + s >>> 1, r = J[n], i = at(r);
    i < e || i === e && r.pre ? t = n + 1 : s = n;
  }
  return t;
}
function Ns(e) {
  (!J.length || !J.includes(
    e,
    ut && e.allowRecurse ? me + 1 : me
  )) && (e.id == null ? J.push(e) : J.splice(yi(e.id), 0, e), tr());
}
function tr() {
  !ut && !as && (as = !0, Ms = Zn.then(nr));
}
function xi(e) {
  const t = J.indexOf(e);
  t > me && J.splice(t, 1);
}
function wi(e) {
  P(e) ? Ge.push(...e) : (!Pe || !Pe.includes(
    e,
    e.allowRecurse ? Ke + 1 : Ke
  )) && Ge.push(e), tr();
}
function sn(e, t, s = ut ? me + 1 : 0) {
  for (; s < J.length; s++) {
    const n = J[s];
    if (n && n.pre) {
      if (e && n.id !== e.uid)
        continue;
      J.splice(s, 1), s--, n();
    }
  }
}
function sr(e) {
  if (Ge.length) {
    const t = [...new Set(Ge)].sort(
      (s, n) => at(s) - at(n)
    );
    if (Ge.length = 0, Pe) {
      Pe.push(...t);
      return;
    }
    for (Pe = t, Ke = 0; Ke < Pe.length; Ke++)
      Pe[Ke]();
    Pe = null, Ke = 0;
  }
}
const at = (e) => e.id == null ? 1 / 0 : e.id, vi = (e, t) => {
  const s = at(e) - at(t);
  if (s === 0) {
    if (e.pre && !t.pre)
      return -1;
    if (t.pre && !e.pre)
      return 1;
  }
  return s;
};
function nr(e) {
  as = !1, ut = !0, J.sort(vi);
  try {
    for (me = 0; me < J.length; me++) {
      const t = J[me];
      t && t.active !== !1 && Re(t, null, 14);
    }
  } finally {
    me = 0, J.length = 0, sr(), ut = !1, Ms = null, (J.length || Ge.length) && nr();
  }
}
function Ci(e, t, ...s) {
  if (e.isUnmounted)
    return;
  const n = e.vnode.props || U;
  let r = s;
  const i = t.startsWith("update:"), o = i && t.slice(7);
  if (o && o in n) {
    const d = `${o === "modelValue" ? "model" : o}Modifiers`, { number: y, trim: C } = n[d] || U;
    C && (r = s.map((S) => q(S) ? S.trim() : S)), y && (r = s.map($r));
  }
  let c, u = n[c = Yt(t)] || // also try camelCase event handler (#2249)
  n[c = Yt(we(t))];
  !u && i && (u = n[c = Yt(ie(t))]), u && ue(
    u,
    e,
    6,
    r
  );
  const a = n[c + "Once"];
  if (a) {
    if (!e.emitted)
      e.emitted = {};
    else if (e.emitted[c])
      return;
    e.emitted[c] = !0, ue(
      a,
      e,
      6,
      r
    );
  }
}
function rr(e, t, s = !1) {
  const n = t.emitsCache, r = n.get(e);
  if (r !== void 0)
    return r;
  const i = e.emits;
  let o = {}, c = !1;
  if (!A(e)) {
    const u = (a) => {
      const d = rr(a, t, !0);
      d && (c = !0, V(o, d));
    };
    !s && t.mixins.length && t.mixins.forEach(u), e.extends && u(e.extends), e.mixins && e.mixins.forEach(u);
  }
  return !i && !c ? (B(e) && n.set(e, null), null) : (P(i) ? i.forEach((u) => o[u] = null) : V(o, i), B(e) && n.set(e, o), o);
}
function Dt(e, t) {
  return !e || !jt(t) ? !1 : (t = t.slice(2).replace(/Once$/, ""), I(e, t[0].toLowerCase() + t.slice(1)) || I(e, ie(t)) || I(e, t));
}
let be = null, ir = null;
function Nt(e) {
  const t = be;
  return be = e, ir = e && e.type.__scopeId || null, t;
}
function Ei(e, t = be, s) {
  if (!t || e._n)
    return e;
  const n = (...r) => {
    n._d && dn(-1);
    const i = Nt(t);
    let o;
    try {
      o = e(...r);
    } finally {
      Nt(i), n._d && dn(1);
    }
    return o;
  };
  return n._n = !0, n._c = !0, n._d = !0, n;
}
function Zt(e) {
  const {
    type: t,
    vnode: s,
    proxy: n,
    withProxy: r,
    propsOptions: [i],
    slots: o,
    attrs: c,
    emit: u,
    render: a,
    renderCache: d,
    props: y,
    data: C,
    setupState: S,
    ctx: j,
    inheritAttrs: M
  } = e, se = Nt(e);
  let W, G;
  try {
    if (s.shapeFlag & 4) {
      const k = r || n, re = k;
      W = _e(
        a.call(
          re,
          k,
          d,
          y,
          S,
          C,
          j
        )
      ), G = c;
    } else {
      const k = t;
      W = _e(
        k.length > 1 ? k(
          y,
          { attrs: c, slots: o, emit: u }
        ) : k(
          y,
          null
        )
      ), G = t.props ? c : Oi(c);
    }
  } catch (k) {
    it.length = 0, Ut(k, e, 1), W = Ce(ht);
  }
  let L = W;
  if (G && M !== !1) {
    const k = Object.keys(G), { shapeFlag: re } = L;
    k.length && re & 7 && (i && k.some(xs) && (G = Pi(
      G,
      i
    )), L = Je(L, G, !1, !0));
  }
  return s.dirs && (L = Je(L, null, !1, !0), L.dirs = L.dirs ? L.dirs.concat(s.dirs) : s.dirs), s.transition && (L.transition = s.transition), W = L, Nt(se), W;
}
const Oi = (e) => {
  let t;
  for (const s in e)
    (s === "class" || s === "style" || jt(s)) && ((t || (t = {}))[s] = e[s]);
  return t;
}, Pi = (e, t) => {
  const s = {};
  for (const n in e)
    (!xs(n) || !(n.slice(9) in t)) && (s[n] = e[n]);
  return s;
};
function Si(e, t, s) {
  const { props: n, children: r, component: i } = e, { props: o, children: c, patchFlag: u } = t, a = i.emitsOptions;
  if (t.dirs || t.transition)
    return !0;
  if (s && u >= 0) {
    if (u & 1024)
      return !0;
    if (u & 16)
      return n ? nn(n, o, a) : !!o;
    if (u & 8) {
      const d = t.dynamicProps;
      for (let y = 0; y < d.length; y++) {
        const C = d[y];
        if (o[C] !== n[C] && !Dt(a, C))
          return !0;
      }
    }
  } else
    return (r || c) && (!c || !c.$stable) ? !0 : n === o ? !1 : n ? o ? nn(n, o, a) : !0 : !!o;
  return !1;
}
function nn(e, t, s) {
  const n = Object.keys(t);
  if (n.length !== Object.keys(e).length)
    return !0;
  for (let r = 0; r < n.length; r++) {
    const i = n[r];
    if (t[i] !== e[i] && !Dt(s, i))
      return !0;
  }
  return !1;
}
function Ti({ vnode: e, parent: t }, s) {
  for (; t; ) {
    const n = t.subTree;
    if (n.suspense && n.suspense.activeBranch === e && (n.el = e.el), n === e)
      (e = t.vnode).el = s, t = t.parent;
    else
      break;
  }
}
const Ai = Symbol.for("v-ndc"), Ri = (e) => e.__isSuspense;
function Ii(e, t) {
  t && t.pendingBranch ? P(e) ? t.effects.push(...e) : t.effects.push(e) : wi(e);
}
const Mi = Symbol.for("v-scx"), Ni = () => Tt(Mi);
function Fi(e, t) {
  return Fs(e, null, t);
}
const Et = {};
function es(e, t, s) {
  return Fs(e, t, s);
}
function Fs(e, t, {
  immediate: s,
  deep: n,
  flush: r,
  once: i,
  onTrack: o,
  onTrigger: c
} = U) {
  if (t && i) {
    const N = t;
    t = (...ye) => {
      N(...ye), re();
    };
  }
  const u = X, a = (N) => n === !0 ? N : (
    // for deep: false, only traverse root-level properties
    We(N, n === !1 ? 1 : void 0)
  );
  let d, y = !1, C = !1;
  if (te(e) ? (d = () => e.value, y = Mt(e)) : st(e) ? (d = () => a(e), y = !0) : P(e) ? (C = !0, y = e.some((N) => st(N) || Mt(N)), d = () => e.map((N) => {
    if (te(N))
      return N.value;
    if (st(N))
      return a(N);
    if (A(N))
      return Re(N, u, 2);
  })) : A(e) ? t ? d = () => Re(e, u, 2) : d = () => (S && S(), ue(
    e,
    u,
    3,
    [j]
  )) : d = oe, t && n) {
    const N = d;
    d = () => We(N());
  }
  let S, j = (N) => {
    S = L.onStop = () => {
      Re(N, u, 4), S = L.onStop = void 0;
    };
  }, M;
  if (qt)
    if (j = oe, t ? s && ue(t, u, 3, [
      d(),
      C ? [] : void 0,
      j
    ]) : d(), r === "sync") {
      const N = Ni();
      M = N.__watcherHandles || (N.__watcherHandles = []);
    } else
      return oe;
  let se = C ? new Array(e.length).fill(Et) : Et;
  const W = () => {
    if (!(!L.active || !L.dirty))
      if (t) {
        const N = L.run();
        (n || y || (C ? N.some((ye, ae) => Ie(ye, se[ae])) : Ie(N, se))) && (S && S(), ue(t, u, 3, [
          N,
          // pass undefined as the old value when it's changed for the first time
          se === Et ? void 0 : C && se[0] === Et ? [] : se,
          j
        ]), se = N);
      } else
        L.run();
  };
  W.allowRecurse = !!t;
  let G;
  r === "sync" ? G = W : r === "post" ? G = () => Z(W, u && u.suspense) : (W.pre = !0, u && (W.id = u.uid), G = () => Ns(W));
  const L = new Es(d, oe, G), k = Wr(), re = () => {
    L.stop(), k && ws(k.effects, L);
  };
  return t ? s ? W() : se = L.run() : r === "post" ? Z(
    L.run.bind(L),
    u && u.suspense
  ) : L.run(), M && M.push(re), re;
}
function $i(e, t, s) {
  const n = this.proxy, r = q(e) ? e.includes(".") ? or(n, e) : () => n[e] : e.bind(n, n);
  let i;
  A(t) ? i = t : (i = t.handler, s = t);
  const o = pt(this), c = Fs(r, i.bind(n), s);
  return o(), c;
}
function or(e, t) {
  const s = t.split(".");
  return () => {
    let n = e;
    for (let r = 0; r < s.length && n; r++)
      n = n[s[r]];
    return n;
  };
}
function We(e, t = 1 / 0, s) {
  if (t <= 0 || !B(e) || e.__v_skip || (s = s || /* @__PURE__ */ new Set(), s.has(e)))
    return e;
  if (s.add(e), t--, te(e))
    We(e.value, t, s);
  else if (P(e))
    for (let n = 0; n < e.length; n++)
      We(e[n], t, s);
  else if (Sn(e) || ze(e))
    e.forEach((n) => {
      We(n, t, s);
    });
  else if (Rn(e))
    for (const n in e)
      We(e[n], t, s);
  return e;
}
function Le(e, t, s, n) {
  const r = e.dirs, i = t && t.dirs;
  for (let o = 0; o < r.length; o++) {
    const c = r[o];
    i && (c.oldValue = i[o].value);
    let u = c.dir[n];
    u && (Me(), ue(u, s, 8, [
      e.el,
      c,
      e,
      t
    ]), Ne());
  }
}
/*! #__NO_SIDE_EFFECTS__ */
// @__NO_SIDE_EFFECTS__
function $s(e, t) {
  return A(e) ? (
    // #8326: extend call and options.name access are considered side-effects
    // by Rollup, so we have to wrap it in a pure-annotated IIFE.
    V({ name: e.name }, t, { setup: e })
  ) : e;
}
const St = (e) => !!e.type.__asyncLoader, lr = (e) => e.type.__isKeepAlive;
function ji(e, t) {
  cr(e, "a", t);
}
function Li(e, t) {
  cr(e, "da", t);
}
function cr(e, t, s = X) {
  const n = e.__wdc || (e.__wdc = () => {
    let r = s;
    for (; r; ) {
      if (r.isDeactivated)
        return;
      r = r.parent;
    }
    return e();
  });
  if (Bt(t, n, s), s) {
    let r = s.parent;
    for (; r && r.parent; )
      lr(r.parent.vnode) && Hi(n, t, s, r), r = r.parent;
  }
}
function Hi(e, t, s, n) {
  const r = Bt(
    t,
    e,
    n,
    !0
    /* prepend */
  );
  fr(() => {
    ws(n[t], r);
  }, s);
}
function Bt(e, t, s = X, n = !1) {
  if (s) {
    const r = s[e] || (s[e] = []), i = t.__weh || (t.__weh = (...o) => {
      if (s.isUnmounted)
        return;
      Me();
      const c = pt(s), u = ue(t, s, e, o);
      return c(), Ne(), u;
    });
    return n ? r.unshift(i) : r.push(i), i;
  }
}
const Ee = (e) => (t, s = X) => (
  // post-create lifecycle registrations are noops during SSR (except for serverPrefetch)
  (!qt || e === "sp") && Bt(e, (...n) => t(...n), s)
), Ki = Ee("bm"), Ui = Ee("m"), Di = Ee("bu"), Bi = Ee("u"), Vi = Ee("bum"), fr = Ee("um"), qi = Ee("sp"), Wi = Ee(
  "rtg"
), ki = Ee(
  "rtc"
);
function zi(e, t = X) {
  Bt("ec", e, t);
}
function Gi(e, t, s, n) {
  let r;
  const i = s && s[n];
  if (P(e) || q(e)) {
    r = new Array(e.length);
    for (let o = 0, c = e.length; o < c; o++)
      r[o] = t(e[o], o, void 0, i && i[o]);
  } else if (typeof e == "number") {
    r = new Array(e);
    for (let o = 0; o < e; o++)
      r[o] = t(o + 1, o, void 0, i && i[o]);
  } else if (B(e))
    if (e[Symbol.iterator])
      r = Array.from(
        e,
        (o, c) => t(o, c, void 0, i && i[c])
      );
    else {
      const o = Object.keys(e);
      r = new Array(o.length);
      for (let c = 0, u = o.length; c < u; c++) {
        const a = o[c];
        r[c] = t(e[a], a, c, i && i[c]);
      }
    }
  else
    r = [];
  return s && (s[n] = r), r;
}
const hs = (e) => e ? Or(e) ? Ks(e) || e.proxy : hs(e.parent) : null, nt = (
  // Move PURE marker to new line to workaround compiler discarding it
  // due to type annotation
  /* @__PURE__ */ V(/* @__PURE__ */ Object.create(null), {
    $: (e) => e,
    $el: (e) => e.vnode.el,
    $data: (e) => e.data,
    $props: (e) => e.props,
    $attrs: (e) => e.attrs,
    $slots: (e) => e.slots,
    $refs: (e) => e.refs,
    $parent: (e) => hs(e.parent),
    $root: (e) => hs(e.root),
    $emit: (e) => e.emit,
    $options: (e) => js(e),
    $forceUpdate: (e) => e.f || (e.f = () => {
      e.effect.dirty = !0, Ns(e.update);
    }),
    $nextTick: (e) => e.n || (e.n = er.bind(e.proxy)),
    $watch: (e) => $i.bind(e)
  })
), ts = (e, t) => e !== U && !e.__isScriptSetup && I(e, t), Ji = {
  get({ _: e }, t) {
    if (t === "__v_skip")
      return !0;
    const { ctx: s, setupState: n, data: r, props: i, accessCache: o, type: c, appContext: u } = e;
    let a;
    if (t[0] !== "$") {
      const S = o[t];
      if (S !== void 0)
        switch (S) {
          case 1:
            return n[t];
          case 2:
            return r[t];
          case 4:
            return s[t];
          case 3:
            return i[t];
        }
      else {
        if (ts(n, t))
          return o[t] = 1, n[t];
        if (r !== U && I(r, t))
          return o[t] = 2, r[t];
        if (
          // only cache other properties when instance has declared (thus stable)
          // props
          (a = e.propsOptions[0]) && I(a, t)
        )
          return o[t] = 3, i[t];
        if (s !== U && I(s, t))
          return o[t] = 4, s[t];
        ds && (o[t] = 0);
      }
    }
    const d = nt[t];
    let y, C;
    if (d)
      return t === "$attrs" && ee(e.attrs, "get", ""), d(e);
    if (
      // css module (injected by vue-loader)
      (y = c.__cssModules) && (y = y[t])
    )
      return y;
    if (s !== U && I(s, t))
      return o[t] = 4, s[t];
    if (
      // global properties
      C = u.config.globalProperties, I(C, t)
    )
      return C[t];
  },
  set({ _: e }, t, s) {
    const { data: n, setupState: r, ctx: i } = e;
    return ts(r, t) ? (r[t] = s, !0) : n !== U && I(n, t) ? (n[t] = s, !0) : I(e.props, t) || t[0] === "$" && t.slice(1) in e ? !1 : (i[t] = s, !0);
  },
  has({
    _: { data: e, setupState: t, accessCache: s, ctx: n, appContext: r, propsOptions: i }
  }, o) {
    let c;
    return !!s[o] || e !== U && I(e, o) || ts(t, o) || (c = i[0]) && I(c, o) || I(n, o) || I(nt, o) || I(r.config.globalProperties, o);
  },
  defineProperty(e, t, s) {
    return s.get != null ? e._.accessCache[t] = 0 : I(s, "value") && this.set(e, t, s.value, null), Reflect.defineProperty(e, t, s);
  }
};
function rn(e) {
  return P(e) ? e.reduce(
    (t, s) => (t[s] = null, t),
    {}
  ) : e;
}
let ds = !0;
function Yi(e) {
  const t = js(e), s = e.proxy, n = e.ctx;
  ds = !1, t.beforeCreate && on(t.beforeCreate, e, "bc");
  const {
    // state
    data: r,
    computed: i,
    methods: o,
    watch: c,
    provide: u,
    inject: a,
    // lifecycle
    created: d,
    beforeMount: y,
    mounted: C,
    beforeUpdate: S,
    updated: j,
    activated: M,
    deactivated: se,
    beforeDestroy: W,
    beforeUnmount: G,
    destroyed: L,
    unmounted: k,
    render: re,
    renderTracked: N,
    renderTriggered: ye,
    errorCaptured: ae,
    serverPrefetch: Wt,
    // public API
    expose: Fe,
    inheritAttrs: Ye,
    // assets
    components: gt,
    directives: _t,
    filters: kt
  } = t;
  if (a && Qi(a, n, null), o)
    for (const D in o) {
      const H = o[D];
      A(H) && (n[D] = H.bind(s));
    }
  if (r) {
    const D = r.call(s, s);
    B(D) && (e.data = As(D));
  }
  if (ds = !0, i)
    for (const D in i) {
      const H = i[D], $e = A(H) ? H.bind(s, s) : A(H.get) ? H.get.bind(s, s) : oe, mt = !A(H) && A(H.set) ? H.set.bind(s) : oe, je = Sr({
        get: $e,
        set: mt
      });
      Object.defineProperty(n, D, {
        enumerable: !0,
        configurable: !0,
        get: () => je.value,
        set: (he) => je.value = he
      });
    }
  if (c)
    for (const D in c)
      ur(c[D], n, s, D);
  if (u) {
    const D = A(u) ? u.call(s) : u;
    Reflect.ownKeys(D).forEach((H) => {
      no(H, D[H]);
    });
  }
  d && on(d, e, "c");
  function Y(D, H) {
    P(H) ? H.forEach(($e) => D($e.bind(s))) : H && D(H.bind(s));
  }
  if (Y(Ki, y), Y(Ui, C), Y(Di, S), Y(Bi, j), Y(ji, M), Y(Li, se), Y(zi, ae), Y(ki, N), Y(Wi, ye), Y(Vi, G), Y(fr, k), Y(qi, Wt), P(Fe))
    if (Fe.length) {
      const D = e.exposed || (e.exposed = {});
      Fe.forEach((H) => {
        Object.defineProperty(D, H, {
          get: () => s[H],
          set: ($e) => s[H] = $e
        });
      });
    } else
      e.exposed || (e.exposed = {});
  re && e.render === oe && (e.render = re), Ye != null && (e.inheritAttrs = Ye), gt && (e.components = gt), _t && (e.directives = _t);
}
function Qi(e, t, s = oe) {
  P(e) && (e = ps(e));
  for (const n in e) {
    const r = e[n];
    let i;
    B(r) ? "default" in r ? i = Tt(
      r.from || n,
      r.default,
      !0
    ) : i = Tt(r.from || n) : i = Tt(r), te(i) ? Object.defineProperty(t, n, {
      enumerable: !0,
      configurable: !0,
      get: () => i.value,
      set: (o) => i.value = o
    }) : t[n] = i;
  }
}
function on(e, t, s) {
  ue(
    P(e) ? e.map((n) => n.bind(t.proxy)) : e.bind(t.proxy),
    t,
    s
  );
}
function ur(e, t, s, n) {
  const r = n.includes(".") ? or(s, n) : () => s[n];
  if (q(e)) {
    const i = t[e];
    A(i) && es(r, i);
  } else if (A(e))
    es(r, e.bind(s));
  else if (B(e))
    if (P(e))
      e.forEach((i) => ur(i, t, s, n));
    else {
      const i = A(e.handler) ? e.handler.bind(s) : t[e.handler];
      A(i) && es(r, i, e);
    }
}
function js(e) {
  const t = e.type, { mixins: s, extends: n } = t, {
    mixins: r,
    optionsCache: i,
    config: { optionMergeStrategies: o }
  } = e.appContext, c = i.get(t);
  let u;
  return c ? u = c : !r.length && !s && !n ? u = t : (u = {}, r.length && r.forEach(
    (a) => Ft(u, a, o, !0)
  ), Ft(u, t, o)), B(t) && i.set(t, u), u;
}
function Ft(e, t, s, n = !1) {
  const { mixins: r, extends: i } = t;
  i && Ft(e, i, s, !0), r && r.forEach(
    (o) => Ft(e, o, s, !0)
  );
  for (const o in t)
    if (!(n && o === "expose")) {
      const c = Xi[o] || s && s[o];
      e[o] = c ? c(e[o], t[o]) : t[o];
    }
  return e;
}
const Xi = {
  data: ln,
  props: cn,
  emits: cn,
  // objects
  methods: et,
  computed: et,
  // lifecycle
  beforeCreate: Q,
  created: Q,
  beforeMount: Q,
  mounted: Q,
  beforeUpdate: Q,
  updated: Q,
  beforeDestroy: Q,
  beforeUnmount: Q,
  destroyed: Q,
  unmounted: Q,
  activated: Q,
  deactivated: Q,
  errorCaptured: Q,
  serverPrefetch: Q,
  // assets
  components: et,
  directives: et,
  // watch
  watch: eo,
  // provide / inject
  provide: ln,
  inject: Zi
};
function ln(e, t) {
  return t ? e ? function() {
    return V(
      A(e) ? e.call(this, this) : e,
      A(t) ? t.call(this, this) : t
    );
  } : t : e;
}
function Zi(e, t) {
  return et(ps(e), ps(t));
}
function ps(e) {
  if (P(e)) {
    const t = {};
    for (let s = 0; s < e.length; s++)
      t[e[s]] = e[s];
    return t;
  }
  return e;
}
function Q(e, t) {
  return e ? [...new Set([].concat(e, t))] : t;
}
function et(e, t) {
  return e ? V(/* @__PURE__ */ Object.create(null), e, t) : t;
}
function cn(e, t) {
  return e ? P(e) && P(t) ? [.../* @__PURE__ */ new Set([...e, ...t])] : V(
    /* @__PURE__ */ Object.create(null),
    rn(e),
    rn(t ?? {})
  ) : t;
}
function eo(e, t) {
  if (!e)
    return t;
  if (!t)
    return e;
  const s = V(/* @__PURE__ */ Object.create(null), e);
  for (const n in t)
    s[n] = Q(e[n], t[n]);
  return s;
}
function ar() {
  return {
    app: null,
    config: {
      isNativeTag: Rr,
      performance: !1,
      globalProperties: {},
      optionMergeStrategies: {},
      errorHandler: void 0,
      warnHandler: void 0,
      compilerOptions: {}
    },
    mixins: [],
    components: {},
    directives: {},
    provides: /* @__PURE__ */ Object.create(null),
    optionsCache: /* @__PURE__ */ new WeakMap(),
    propsCache: /* @__PURE__ */ new WeakMap(),
    emitsCache: /* @__PURE__ */ new WeakMap()
  };
}
let to = 0;
function so(e, t) {
  return function(n, r = null) {
    A(n) || (n = V({}, n)), r != null && !B(r) && (r = null);
    const i = ar(), o = /* @__PURE__ */ new WeakSet();
    let c = !1;
    const u = i.app = {
      _uid: to++,
      _component: n,
      _props: r,
      _container: null,
      _context: i,
      _instance: null,
      version: Ao,
      get config() {
        return i.config;
      },
      set config(a) {
      },
      use(a, ...d) {
        return o.has(a) || (a && A(a.install) ? (o.add(a), a.install(u, ...d)) : A(a) && (o.add(a), a(u, ...d))), u;
      },
      mixin(a) {
        return i.mixins.includes(a) || i.mixins.push(a), u;
      },
      component(a, d) {
        return d ? (i.components[a] = d, u) : i.components[a];
      },
      directive(a, d) {
        return d ? (i.directives[a] = d, u) : i.directives[a];
      },
      mount(a, d, y) {
        if (!c) {
          const C = Ce(n, r);
          return C.appContext = i, y === !0 ? y = "svg" : y === !1 && (y = void 0), d && t ? t(C, a) : e(C, a, y), c = !0, u._container = a, a.__vue_app__ = u, Ks(C.component) || C.component.proxy;
        }
      },
      unmount() {
        c && (e(null, u._container), delete u._container.__vue_app__);
      },
      provide(a, d) {
        return i.provides[a] = d, u;
      },
      runWithContext(a) {
        const d = rt;
        rt = u;
        try {
          return a();
        } finally {
          rt = d;
        }
      }
    };
    return u;
  };
}
let rt = null;
function no(e, t) {
  if (X) {
    let s = X.provides;
    const n = X.parent && X.parent.provides;
    n === s && (s = X.provides = Object.create(n)), s[e] = t;
  }
}
function Tt(e, t, s = !1) {
  const n = X || be;
  if (n || rt) {
    const r = n ? n.parent == null ? n.vnode.appContext && n.vnode.appContext.provides : n.parent.provides : rt._context.provides;
    if (r && e in r)
      return r[e];
    if (arguments.length > 1)
      return s && A(t) ? t.call(n && n.proxy) : t;
  }
}
const hr = {}, dr = () => Object.create(hr), pr = (e) => Object.getPrototypeOf(e) === hr;
function ro(e, t, s, n = !1) {
  const r = {}, i = dr();
  e.propsDefaults = /* @__PURE__ */ Object.create(null), gr(e, t, r, i);
  for (const o in e.propsOptions[0])
    o in r || (r[o] = void 0);
  s ? e.props = n ? r : ai(r) : e.type.props ? e.props = r : e.props = i, e.attrs = i;
}
function io(e, t, s, n) {
  const {
    props: r,
    attrs: i,
    vnode: { patchFlag: o }
  } = e, c = F(r), [u] = e.propsOptions;
  let a = !1;
  if (
    // always force full diff in dev
    // - #1942 if hmr is enabled with sfc component
    // - vite#872 non-sfc component used by sfc component
    (n || o > 0) && !(o & 16)
  ) {
    if (o & 8) {
      const d = e.vnode.dynamicProps;
      for (let y = 0; y < d.length; y++) {
        let C = d[y];
        if (Dt(e.emitsOptions, C))
          continue;
        const S = t[C];
        if (u)
          if (I(i, C))
            S !== i[C] && (i[C] = S, a = !0);
          else {
            const j = we(C);
            r[j] = gs(
              u,
              c,
              j,
              S,
              e,
              !1
            );
          }
        else
          S !== i[C] && (i[C] = S, a = !0);
      }
    }
  } else {
    gr(e, t, r, i) && (a = !0);
    let d;
    for (const y in c)
      (!t || // for camelCase
      !I(t, y) && // it's possible the original props was passed in as kebab-case
      // and converted to camelCase (#955)
      ((d = ie(y)) === y || !I(t, d))) && (u ? s && // for camelCase
      (s[y] !== void 0 || // for kebab-case
      s[d] !== void 0) && (r[y] = gs(
        u,
        c,
        y,
        void 0,
        e,
        !0
      )) : delete r[y]);
    if (i !== c)
      for (const y in i)
        (!t || !I(t, y)) && (delete i[y], a = !0);
  }
  a && ve(e.attrs, "set", "");
}
function gr(e, t, s, n) {
  const [r, i] = e.propsOptions;
  let o = !1, c;
  if (t)
    for (let u in t) {
      if (tt(u))
        continue;
      const a = t[u];
      let d;
      r && I(r, d = we(u)) ? !i || !i.includes(d) ? s[d] = a : (c || (c = {}))[d] = a : Dt(e.emitsOptions, u) || (!(u in n) || a !== n[u]) && (n[u] = a, o = !0);
    }
  if (i) {
    const u = F(s), a = c || U;
    for (let d = 0; d < i.length; d++) {
      const y = i[d];
      s[y] = gs(
        r,
        u,
        y,
        a[y],
        e,
        !I(a, y)
      );
    }
  }
  return o;
}
function gs(e, t, s, n, r, i) {
  const o = e[s];
  if (o != null) {
    const c = I(o, "default");
    if (c && n === void 0) {
      const u = o.default;
      if (o.type !== Function && !o.skipFactory && A(u)) {
        const { propsDefaults: a } = r;
        if (s in a)
          n = a[s];
        else {
          const d = pt(r);
          n = a[s] = u.call(
            null,
            t
          ), d();
        }
      } else
        n = u;
    }
    o[
      0
      /* shouldCast */
    ] && (i && !c ? n = !1 : o[
      1
      /* shouldCastTrue */
    ] && (n === "" || n === ie(s)) && (n = !0));
  }
  return n;
}
function _r(e, t, s = !1) {
  const n = t.propsCache, r = n.get(e);
  if (r)
    return r;
  const i = e.props, o = {}, c = [];
  let u = !1;
  if (!A(e)) {
    const d = (y) => {
      u = !0;
      const [C, S] = _r(y, t, !0);
      V(o, C), S && c.push(...S);
    };
    !s && t.mixins.length && t.mixins.forEach(d), e.extends && d(e.extends), e.mixins && e.mixins.forEach(d);
  }
  if (!i && !u)
    return B(e) && n.set(e, ke), ke;
  if (P(i))
    for (let d = 0; d < i.length; d++) {
      const y = we(i[d]);
      fn(y) && (o[y] = U);
    }
  else if (i)
    for (const d in i) {
      const y = we(d);
      if (fn(y)) {
        const C = i[d], S = o[y] = P(C) || A(C) ? { type: C } : V({}, C);
        if (S) {
          const j = hn(Boolean, S.type), M = hn(String, S.type);
          S[
            0
            /* shouldCast */
          ] = j > -1, S[
            1
            /* shouldCastTrue */
          ] = M < 0 || j < M, (j > -1 || I(S, "default")) && c.push(y);
        }
      }
    }
  const a = [o, c];
  return B(e) && n.set(e, a), a;
}
function fn(e) {
  return e[0] !== "$" && !tt(e);
}
function un(e) {
  return e === null ? "null" : typeof e == "function" ? e.name || "" : typeof e == "object" && e.constructor && e.constructor.name || "";
}
function an(e, t) {
  return un(e) === un(t);
}
function hn(e, t) {
  return P(t) ? t.findIndex((s) => an(s, e)) : A(t) && an(t, e) ? 0 : -1;
}
const mr = (e) => e[0] === "_" || e === "$stable", Ls = (e) => P(e) ? e.map(_e) : [_e(e)], oo = (e, t, s) => {
  if (t._n)
    return t;
  const n = Ei((...r) => Ls(t(...r)), s);
  return n._c = !1, n;
}, br = (e, t, s) => {
  const n = e._ctx;
  for (const r in e) {
    if (mr(r))
      continue;
    const i = e[r];
    if (A(i))
      t[r] = oo(r, i, n);
    else if (i != null) {
      const o = Ls(i);
      t[r] = () => o;
    }
  }
}, yr = (e, t) => {
  const s = Ls(t);
  e.slots.default = () => s;
}, lo = (e, t) => {
  const s = e.slots = dr();
  if (e.vnode.shapeFlag & 32) {
    const n = t._;
    n ? (V(s, t), Mn(s, "_", n, !0)) : br(t, s);
  } else
    t && yr(e, t);
}, co = (e, t, s) => {
  const { vnode: n, slots: r } = e;
  let i = !0, o = U;
  if (n.shapeFlag & 32) {
    const c = t._;
    c ? s && c === 1 ? i = !1 : (V(r, t), !s && c === 1 && delete r._) : (i = !t.$stable, br(t, r)), o = t;
  } else
    t && (yr(e, t), o = { default: 1 });
  if (i)
    for (const c in r)
      !mr(c) && o[c] == null && delete r[c];
};
function _s(e, t, s, n, r = !1) {
  if (P(e)) {
    e.forEach(
      (C, S) => _s(
        C,
        t && (P(t) ? t[S] : t),
        s,
        n,
        r
      )
    );
    return;
  }
  if (St(n) && !r)
    return;
  const i = n.shapeFlag & 4 ? Ks(n.component) || n.component.proxy : n.el, o = r ? null : i, { i: c, r: u } = e, a = t && t.r, d = c.refs === U ? c.refs = {} : c.refs, y = c.setupState;
  if (a != null && a !== u && (q(a) ? (d[a] = null, I(y, a) && (y[a] = null)) : te(a) && (a.value = null)), A(u))
    Re(u, c, 12, [o, d]);
  else {
    const C = q(u), S = te(u);
    if (C || S) {
      const j = () => {
        if (e.f) {
          const M = C ? I(y, u) ? y[u] : d[u] : u.value;
          r ? P(M) && ws(M, i) : P(M) ? M.includes(i) || M.push(i) : C ? (d[u] = [i], I(y, u) && (y[u] = d[u])) : (u.value = [i], e.k && (d[e.k] = u.value));
        } else
          C ? (d[u] = o, I(y, u) && (y[u] = o)) : S && (u.value = o, e.k && (d[e.k] = o));
      };
      o ? (j.id = -1, Z(j, s)) : j();
    }
  }
}
const Z = Ii;
function fo(e) {
  return uo(e);
}
function uo(e, t) {
  const s = Nn();
  s.__VUE__ = !0;
  const {
    insert: n,
    remove: r,
    patchProp: i,
    createElement: o,
    createText: c,
    createComment: u,
    setText: a,
    setElementText: d,
    parentNode: y,
    nextSibling: C,
    setScopeId: S = oe,
    insertStaticContent: j
  } = e, M = (l, f, h, p = null, g = null, b = null, w = void 0, m = null, x = !!f.dynamicChildren) => {
    if (l === f)
      return;
    l && !Xe(l, f) && (p = bt(l), he(l, g, b, !0), l = null), f.patchFlag === -2 && (x = !1, f.dynamicChildren = null);
    const { type: _, ref: v, shapeFlag: O } = f;
    switch (_) {
      case Vt:
        se(l, f, h, p);
        break;
      case ht:
        W(l, f, h, p);
        break;
      case ns:
        l == null && G(f, h, p, w);
        break;
      case ge:
        gt(
          l,
          f,
          h,
          p,
          g,
          b,
          w,
          m,
          x
        );
        break;
      default:
        O & 1 ? re(
          l,
          f,
          h,
          p,
          g,
          b,
          w,
          m,
          x
        ) : O & 6 ? _t(
          l,
          f,
          h,
          p,
          g,
          b,
          w,
          m,
          x
        ) : (O & 64 || O & 128) && _.process(
          l,
          f,
          h,
          p,
          g,
          b,
          w,
          m,
          x,
          Ve
        );
    }
    v != null && g && _s(v, l && l.ref, b, f || l, !f);
  }, se = (l, f, h, p) => {
    if (l == null)
      n(
        f.el = c(f.children),
        h,
        p
      );
    else {
      const g = f.el = l.el;
      f.children !== l.children && a(g, f.children);
    }
  }, W = (l, f, h, p) => {
    l == null ? n(
      f.el = u(f.children || ""),
      h,
      p
    ) : f.el = l.el;
  }, G = (l, f, h, p) => {
    [l.el, l.anchor] = j(
      l.children,
      f,
      h,
      p,
      l.el,
      l.anchor
    );
  }, L = ({ el: l, anchor: f }, h, p) => {
    let g;
    for (; l && l !== f; )
      g = C(l), n(l, h, p), l = g;
    n(f, h, p);
  }, k = ({ el: l, anchor: f }) => {
    let h;
    for (; l && l !== f; )
      h = C(l), r(l), l = h;
    r(f);
  }, re = (l, f, h, p, g, b, w, m, x) => {
    f.type === "svg" ? w = "svg" : f.type === "math" && (w = "mathml"), l == null ? N(
      f,
      h,
      p,
      g,
      b,
      w,
      m,
      x
    ) : Wt(
      l,
      f,
      g,
      b,
      w,
      m,
      x
    );
  }, N = (l, f, h, p, g, b, w, m) => {
    let x, _;
    const { props: v, shapeFlag: O, transition: E, dirs: T } = l;
    if (x = l.el = o(
      l.type,
      b,
      v && v.is,
      v
    ), O & 8 ? d(x, l.children) : O & 16 && ae(
      l.children,
      x,
      null,
      p,
      g,
      ss(l, b),
      w,
      m
    ), T && Le(l, null, p, "created"), ye(x, l, l.scopeId, w, p), v) {
      for (const $ in v)
        $ !== "value" && !tt($) && i(
          x,
          $,
          null,
          v[$],
          b,
          l.children,
          p,
          g,
          xe
        );
      "value" in v && i(x, "value", null, v.value, b), (_ = v.onVnodeBeforeMount) && pe(_, p, l);
    }
    T && Le(l, null, p, "beforeMount");
    const R = ao(g, E);
    R && E.beforeEnter(x), n(x, f, h), ((_ = v && v.onVnodeMounted) || R || T) && Z(() => {
      _ && pe(_, p, l), R && E.enter(x), T && Le(l, null, p, "mounted");
    }, g);
  }, ye = (l, f, h, p, g) => {
    if (h && S(l, h), p)
      for (let b = 0; b < p.length; b++)
        S(l, p[b]);
    if (g) {
      let b = g.subTree;
      if (f === b) {
        const w = g.vnode;
        ye(
          l,
          w,
          w.scopeId,
          w.slotScopeIds,
          g.parent
        );
      }
    }
  }, ae = (l, f, h, p, g, b, w, m, x = 0) => {
    for (let _ = x; _ < l.length; _++) {
      const v = l[_] = m ? Se(l[_]) : _e(l[_]);
      M(
        null,
        v,
        f,
        h,
        p,
        g,
        b,
        w,
        m
      );
    }
  }, Wt = (l, f, h, p, g, b, w) => {
    const m = f.el = l.el;
    let { patchFlag: x, dynamicChildren: _, dirs: v } = f;
    x |= l.patchFlag & 16;
    const O = l.props || U, E = f.props || U;
    let T;
    if (h && He(h, !1), (T = E.onVnodeBeforeUpdate) && pe(T, h, f, l), v && Le(f, l, h, "beforeUpdate"), h && He(h, !0), _ ? Fe(
      l.dynamicChildren,
      _,
      m,
      h,
      p,
      ss(f, g),
      b
    ) : w || H(
      l,
      f,
      m,
      null,
      h,
      p,
      ss(f, g),
      b,
      !1
    ), x > 0) {
      if (x & 16)
        Ye(
          m,
          f,
          O,
          E,
          h,
          p,
          g
        );
      else if (x & 2 && O.class !== E.class && i(m, "class", null, E.class, g), x & 4 && i(m, "style", O.style, E.style, g), x & 8) {
        const R = f.dynamicProps;
        for (let $ = 0; $ < R.length; $++) {
          const K = R[$], z = O[K], le = E[K];
          (le !== z || K === "value") && i(
            m,
            K,
            z,
            le,
            g,
            l.children,
            h,
            p,
            xe
          );
        }
      }
      x & 1 && l.children !== f.children && d(m, f.children);
    } else
      !w && _ == null && Ye(
        m,
        f,
        O,
        E,
        h,
        p,
        g
      );
    ((T = E.onVnodeUpdated) || v) && Z(() => {
      T && pe(T, h, f, l), v && Le(f, l, h, "updated");
    }, p);
  }, Fe = (l, f, h, p, g, b, w) => {
    for (let m = 0; m < f.length; m++) {
      const x = l[m], _ = f[m], v = (
        // oldVNode may be an errored async setup() component inside Suspense
        // which will not have a mounted element
        x.el && // - In the case of a Fragment, we need to provide the actual parent
        // of the Fragment itself so it can move its children.
        (x.type === ge || // - In the case of different nodes, there is going to be a replacement
        // which also requires the correct parent container
        !Xe(x, _) || // - In the case of a component, it could contain anything.
        x.shapeFlag & 70) ? y(x.el) : (
          // In other cases, the parent container is not actually used so we
          // just pass the block element here to avoid a DOM parentNode call.
          h
        )
      );
      M(
        x,
        _,
        v,
        null,
        p,
        g,
        b,
        w,
        !0
      );
    }
  }, Ye = (l, f, h, p, g, b, w) => {
    if (h !== p) {
      if (h !== U)
        for (const m in h)
          !tt(m) && !(m in p) && i(
            l,
            m,
            h[m],
            null,
            w,
            f.children,
            g,
            b,
            xe
          );
      for (const m in p) {
        if (tt(m))
          continue;
        const x = p[m], _ = h[m];
        x !== _ && m !== "value" && i(
          l,
          m,
          _,
          x,
          w,
          f.children,
          g,
          b,
          xe
        );
      }
      "value" in p && i(l, "value", h.value, p.value, w);
    }
  }, gt = (l, f, h, p, g, b, w, m, x) => {
    const _ = f.el = l ? l.el : c(""), v = f.anchor = l ? l.anchor : c("");
    let { patchFlag: O, dynamicChildren: E, slotScopeIds: T } = f;
    T && (m = m ? m.concat(T) : T), l == null ? (n(_, h, p), n(v, h, p), ae(
      // #10007
      // such fragment like `<></>` will be compiled into
      // a fragment which doesn't have a children.
      // In this case fallback to an empty array
      f.children || [],
      h,
      v,
      g,
      b,
      w,
      m,
      x
    )) : O > 0 && O & 64 && E && // #2715 the previous fragment could've been a BAILed one as a result
    // of renderSlot() with no valid children
    l.dynamicChildren ? (Fe(
      l.dynamicChildren,
      E,
      h,
      g,
      b,
      w,
      m
    ), // #2080 if the stable fragment has a key, it's a <template v-for> that may
    //  get moved around. Make sure all root level vnodes inherit el.
    // #2134 or if it's a component root, it may also get moved around
    // as the component is being moved.
    (f.key != null || g && f === g.subTree) && xr(
      l,
      f,
      !0
      /* shallow */
    )) : H(
      l,
      f,
      h,
      v,
      g,
      b,
      w,
      m,
      x
    );
  }, _t = (l, f, h, p, g, b, w, m, x) => {
    f.slotScopeIds = m, l == null ? f.shapeFlag & 512 ? g.ctx.activate(
      f,
      h,
      p,
      w,
      x
    ) : kt(
      f,
      h,
      p,
      g,
      b,
      w,
      x
    ) : Ds(l, f, x);
  }, kt = (l, f, h, p, g, b, w) => {
    const m = l.component = Co(
      l,
      p,
      g
    );
    if (lr(l) && (m.ctx.renderer = Ve), Eo(m), m.asyncDep) {
      if (g && g.registerDep(m, Y), !l.el) {
        const x = m.subTree = Ce(ht);
        W(null, x, f, h);
      }
    } else
      Y(
        m,
        l,
        f,
        h,
        g,
        b,
        w
      );
  }, Ds = (l, f, h) => {
    const p = f.component = l.component;
    if (Si(l, f, h))
      if (p.asyncDep && !p.asyncResolved) {
        D(p, f, h);
        return;
      } else
        p.next = f, xi(p.update), p.effect.dirty = !0, p.update();
    else
      f.el = l.el, p.vnode = f;
  }, Y = (l, f, h, p, g, b, w) => {
    const m = () => {
      if (l.isMounted) {
        let { next: v, bu: O, u: E, parent: T, vnode: R } = l;
        {
          const qe = wr(l);
          if (qe) {
            v && (v.el = R.el, D(l, v, w)), qe.asyncDep.then(() => {
              l.isUnmounted || m();
            });
            return;
          }
        }
        let $ = v, K;
        He(l, !1), v ? (v.el = R.el, D(l, v, w)) : v = R, O && Qt(O), (K = v.props && v.props.onVnodeBeforeUpdate) && pe(K, T, v, R), He(l, !0);
        const z = Zt(l), le = l.subTree;
        l.subTree = z, M(
          le,
          z,
          // parent may have changed if it's in a teleport
          y(le.el),
          // anchor may have changed if it's in a fragment
          bt(le),
          l,
          g,
          b
        ), v.el = z.el, $ === null && Ti(l, z.el), E && Z(E, g), (K = v.props && v.props.onVnodeUpdated) && Z(
          () => pe(K, T, v, R),
          g
        );
      } else {
        let v;
        const { el: O, props: E } = f, { bm: T, m: R, parent: $ } = l, K = St(f);
        if (He(l, !1), T && Qt(T), !K && (v = E && E.onVnodeBeforeMount) && pe(v, $, f), He(l, !0), O && Jt) {
          const z = () => {
            l.subTree = Zt(l), Jt(
              O,
              l.subTree,
              l,
              g,
              null
            );
          };
          K ? f.type.__asyncLoader().then(
            // note: we are moving the render call into an async callback,
            // which means it won't track dependencies - but it's ok because
            // a server-rendered async wrapper is already in resolved state
            // and it will never need to change.
            () => !l.isUnmounted && z()
          ) : z();
        } else {
          const z = l.subTree = Zt(l);
          M(
            null,
            z,
            h,
            p,
            l,
            g,
            b
          ), f.el = z.el;
        }
        if (R && Z(R, g), !K && (v = E && E.onVnodeMounted)) {
          const z = f;
          Z(
            () => pe(v, $, z),
            g
          );
        }
        (f.shapeFlag & 256 || $ && St($.vnode) && $.vnode.shapeFlag & 256) && l.a && Z(l.a, g), l.isMounted = !0, f = h = p = null;
      }
    }, x = l.effect = new Es(
      m,
      oe,
      () => Ns(_),
      l.scope
      // track it in component's effect scope
    ), _ = l.update = () => {
      x.dirty && x.run();
    };
    _.id = l.uid, He(l, !0), _();
  }, D = (l, f, h) => {
    f.component = l;
    const p = l.vnode.props;
    l.vnode = f, l.next = null, io(l, f.props, p, h), co(l, f.children, h), Me(), sn(l), Ne();
  }, H = (l, f, h, p, g, b, w, m, x = !1) => {
    const _ = l && l.children, v = l ? l.shapeFlag : 0, O = f.children, { patchFlag: E, shapeFlag: T } = f;
    if (E > 0) {
      if (E & 128) {
        mt(
          _,
          O,
          h,
          p,
          g,
          b,
          w,
          m,
          x
        );
        return;
      } else if (E & 256) {
        $e(
          _,
          O,
          h,
          p,
          g,
          b,
          w,
          m,
          x
        );
        return;
      }
    }
    T & 8 ? (v & 16 && xe(_, g, b), O !== _ && d(h, O)) : v & 16 ? T & 16 ? mt(
      _,
      O,
      h,
      p,
      g,
      b,
      w,
      m,
      x
    ) : xe(_, g, b, !0) : (v & 8 && d(h, ""), T & 16 && ae(
      O,
      h,
      p,
      g,
      b,
      w,
      m,
      x
    ));
  }, $e = (l, f, h, p, g, b, w, m, x) => {
    l = l || ke, f = f || ke;
    const _ = l.length, v = f.length, O = Math.min(_, v);
    let E;
    for (E = 0; E < O; E++) {
      const T = f[E] = x ? Se(f[E]) : _e(f[E]);
      M(
        l[E],
        T,
        h,
        null,
        g,
        b,
        w,
        m,
        x
      );
    }
    _ > v ? xe(
      l,
      g,
      b,
      !0,
      !1,
      O
    ) : ae(
      f,
      h,
      p,
      g,
      b,
      w,
      m,
      x,
      O
    );
  }, mt = (l, f, h, p, g, b, w, m, x) => {
    let _ = 0;
    const v = f.length;
    let O = l.length - 1, E = v - 1;
    for (; _ <= O && _ <= E; ) {
      const T = l[_], R = f[_] = x ? Se(f[_]) : _e(f[_]);
      if (Xe(T, R))
        M(
          T,
          R,
          h,
          null,
          g,
          b,
          w,
          m,
          x
        );
      else
        break;
      _++;
    }
    for (; _ <= O && _ <= E; ) {
      const T = l[O], R = f[E] = x ? Se(f[E]) : _e(f[E]);
      if (Xe(T, R))
        M(
          T,
          R,
          h,
          null,
          g,
          b,
          w,
          m,
          x
        );
      else
        break;
      O--, E--;
    }
    if (_ > O) {
      if (_ <= E) {
        const T = E + 1, R = T < v ? f[T].el : p;
        for (; _ <= E; )
          M(
            null,
            f[_] = x ? Se(f[_]) : _e(f[_]),
            h,
            R,
            g,
            b,
            w,
            m,
            x
          ), _++;
      }
    } else if (_ > E)
      for (; _ <= O; )
        he(l[_], g, b, !0), _++;
    else {
      const T = _, R = _, $ = /* @__PURE__ */ new Map();
      for (_ = R; _ <= E; _++) {
        const ne = f[_] = x ? Se(f[_]) : _e(f[_]);
        ne.key != null && $.set(ne.key, _);
      }
      let K, z = 0;
      const le = E - R + 1;
      let qe = !1, qs = 0;
      const Qe = new Array(le);
      for (_ = 0; _ < le; _++)
        Qe[_] = 0;
      for (_ = T; _ <= O; _++) {
        const ne = l[_];
        if (z >= le) {
          he(ne, g, b, !0);
          continue;
        }
        let de;
        if (ne.key != null)
          de = $.get(ne.key);
        else
          for (K = R; K <= E; K++)
            if (Qe[K - R] === 0 && Xe(ne, f[K])) {
              de = K;
              break;
            }
        de === void 0 ? he(ne, g, b, !0) : (Qe[de - R] = _ + 1, de >= qs ? qs = de : qe = !0, M(
          ne,
          f[de],
          h,
          null,
          g,
          b,
          w,
          m,
          x
        ), z++);
      }
      const Ws = qe ? ho(Qe) : ke;
      for (K = Ws.length - 1, _ = le - 1; _ >= 0; _--) {
        const ne = R + _, de = f[ne], ks = ne + 1 < v ? f[ne + 1].el : p;
        Qe[_] === 0 ? M(
          null,
          de,
          h,
          ks,
          g,
          b,
          w,
          m,
          x
        ) : qe && (K < 0 || _ !== Ws[K] ? je(de, h, ks, 2) : K--);
      }
    }
  }, je = (l, f, h, p, g = null) => {
    const { el: b, type: w, transition: m, children: x, shapeFlag: _ } = l;
    if (_ & 6) {
      je(l.component.subTree, f, h, p);
      return;
    }
    if (_ & 128) {
      l.suspense.move(f, h, p);
      return;
    }
    if (_ & 64) {
      w.move(l, f, h, Ve);
      return;
    }
    if (w === ge) {
      n(b, f, h);
      for (let O = 0; O < x.length; O++)
        je(x[O], f, h, p);
      n(l.anchor, f, h);
      return;
    }
    if (w === ns) {
      L(l, f, h);
      return;
    }
    if (p !== 2 && _ & 1 && m)
      if (p === 0)
        m.beforeEnter(b), n(b, f, h), Z(() => m.enter(b), g);
      else {
        const { leave: O, delayLeave: E, afterLeave: T } = m, R = () => n(b, f, h), $ = () => {
          O(b, () => {
            R(), T && T();
          });
        };
        E ? E(b, R, $) : $();
      }
    else
      n(b, f, h);
  }, he = (l, f, h, p = !1, g = !1) => {
    const {
      type: b,
      props: w,
      ref: m,
      children: x,
      dynamicChildren: _,
      shapeFlag: v,
      patchFlag: O,
      dirs: E
    } = l;
    if (m != null && _s(m, null, h, l, !0), v & 256) {
      f.ctx.deactivate(l);
      return;
    }
    const T = v & 1 && E, R = !St(l);
    let $;
    if (R && ($ = w && w.onVnodeBeforeUnmount) && pe($, f, l), v & 6)
      Ar(l.component, h, p);
    else {
      if (v & 128) {
        l.suspense.unmount(h, p);
        return;
      }
      T && Le(l, null, f, "beforeUnmount"), v & 64 ? l.type.remove(
        l,
        f,
        h,
        g,
        Ve,
        p
      ) : _ && // #1153: fast path should not be taken for non-stable (v-for) fragments
      (b !== ge || O > 0 && O & 64) ? xe(
        _,
        f,
        h,
        !1,
        !0
      ) : (b === ge && O & 384 || !g && v & 16) && xe(x, f, h), p && Bs(l);
    }
    (R && ($ = w && w.onVnodeUnmounted) || T) && Z(() => {
      $ && pe($, f, l), T && Le(l, null, f, "unmounted");
    }, h);
  }, Bs = (l) => {
    const { type: f, el: h, anchor: p, transition: g } = l;
    if (f === ge) {
      Tr(h, p);
      return;
    }
    if (f === ns) {
      k(l);
      return;
    }
    const b = () => {
      r(h), g && !g.persisted && g.afterLeave && g.afterLeave();
    };
    if (l.shapeFlag & 1 && g && !g.persisted) {
      const { leave: w, delayLeave: m } = g, x = () => w(h, b);
      m ? m(l.el, b, x) : x();
    } else
      b();
  }, Tr = (l, f) => {
    let h;
    for (; l !== f; )
      h = C(l), r(l), l = h;
    r(f);
  }, Ar = (l, f, h) => {
    const { bum: p, scope: g, update: b, subTree: w, um: m } = l;
    p && Qt(p), g.stop(), b && (b.active = !1, he(w, l, f, h)), m && Z(m, f), Z(() => {
      l.isUnmounted = !0;
    }, f), f && f.pendingBranch && !f.isUnmounted && l.asyncDep && !l.asyncResolved && l.suspenseId === f.pendingId && (f.deps--, f.deps === 0 && f.resolve());
  }, xe = (l, f, h, p = !1, g = !1, b = 0) => {
    for (let w = b; w < l.length; w++)
      he(l[w], f, h, p, g);
  }, bt = (l) => l.shapeFlag & 6 ? bt(l.component.subTree) : l.shapeFlag & 128 ? l.suspense.next() : C(l.anchor || l.el);
  let zt = !1;
  const Vs = (l, f, h) => {
    l == null ? f._vnode && he(f._vnode, null, null, !0) : M(
      f._vnode || null,
      l,
      f,
      null,
      null,
      null,
      h
    ), zt || (zt = !0, sn(), sr(), zt = !1), f._vnode = l;
  }, Ve = {
    p: M,
    um: he,
    m: je,
    r: Bs,
    mt: kt,
    mc: ae,
    pc: H,
    pbc: Fe,
    n: bt,
    o: e
  };
  let Gt, Jt;
  return t && ([Gt, Jt] = t(
    Ve
  )), {
    render: Vs,
    hydrate: Gt,
    createApp: so(Vs, Gt)
  };
}
function ss({ type: e, props: t }, s) {
  return s === "svg" && e === "foreignObject" || s === "mathml" && e === "annotation-xml" && t && t.encoding && t.encoding.includes("html") ? void 0 : s;
}
function He({ effect: e, update: t }, s) {
  e.allowRecurse = t.allowRecurse = s;
}
function ao(e, t) {
  return (!e || e && !e.pendingBranch) && t && !t.persisted;
}
function xr(e, t, s = !1) {
  const n = e.children, r = t.children;
  if (P(n) && P(r))
    for (let i = 0; i < n.length; i++) {
      const o = n[i];
      let c = r[i];
      c.shapeFlag & 1 && !c.dynamicChildren && ((c.patchFlag <= 0 || c.patchFlag === 32) && (c = r[i] = Se(r[i]), c.el = o.el), s || xr(o, c)), c.type === Vt && (c.el = o.el);
    }
}
function ho(e) {
  const t = e.slice(), s = [0];
  let n, r, i, o, c;
  const u = e.length;
  for (n = 0; n < u; n++) {
    const a = e[n];
    if (a !== 0) {
      if (r = s[s.length - 1], e[r] < a) {
        t[n] = r, s.push(n);
        continue;
      }
      for (i = 0, o = s.length - 1; i < o; )
        c = i + o >> 1, e[s[c]] < a ? i = c + 1 : o = c;
      a < e[s[i]] && (i > 0 && (t[n] = s[i - 1]), s[i] = n);
    }
  }
  for (i = s.length, o = s[i - 1]; i-- > 0; )
    s[i] = o, o = t[o];
  return s;
}
function wr(e) {
  const t = e.subTree.component;
  if (t)
    return t.asyncDep && !t.asyncResolved ? t : wr(t);
}
const po = (e) => e.__isTeleport, ge = Symbol.for("v-fgt"), Vt = Symbol.for("v-txt"), ht = Symbol.for("v-cmt"), ns = Symbol.for("v-stc"), it = [];
let fe = null;
function At(e = !1) {
  it.push(fe = e ? null : []);
}
function go() {
  it.pop(), fe = it[it.length - 1] || null;
}
let dt = 1;
function dn(e) {
  dt += e;
}
function vr(e) {
  return e.dynamicChildren = dt > 0 ? fe || ke : null, go(), dt > 0 && fe && fe.push(e), e;
}
function ms(e, t, s, n, r, i) {
  return vr(
    ot(
      e,
      t,
      s,
      n,
      r,
      i,
      !0
    )
  );
}
function _o(e, t, s, n, r) {
  return vr(
    Ce(
      e,
      t,
      s,
      n,
      r,
      !0
    )
  );
}
function mo(e) {
  return e ? e.__v_isVNode === !0 : !1;
}
function Xe(e, t) {
  return e.type === t.type && e.key === t.key;
}
const Cr = ({ key: e }) => e ?? null, Rt = ({
  ref: e,
  ref_key: t,
  ref_for: s
}) => (typeof e == "number" && (e = "" + e), e != null ? q(e) || te(e) || A(e) ? { i: be, r: e, k: t, f: !!s } : e : null);
function ot(e, t = null, s = null, n = 0, r = null, i = e === ge ? 0 : 1, o = !1, c = !1) {
  const u = {
    __v_isVNode: !0,
    __v_skip: !0,
    type: e,
    props: t,
    key: t && Cr(t),
    ref: t && Rt(t),
    scopeId: ir,
    slotScopeIds: null,
    children: s,
    component: null,
    suspense: null,
    ssContent: null,
    ssFallback: null,
    dirs: null,
    transition: null,
    el: null,
    anchor: null,
    target: null,
    targetAnchor: null,
    staticCount: 0,
    shapeFlag: i,
    patchFlag: n,
    dynamicProps: r,
    dynamicChildren: null,
    appContext: null,
    ctx: be
  };
  return c ? (Hs(u, s), i & 128 && e.normalize(u)) : s && (u.shapeFlag |= q(s) ? 8 : 16), dt > 0 && // avoid a block node from tracking itself
  !o && // has current parent block
  fe && // presence of a patch flag indicates this node needs patching on updates.
  // component nodes also should always be patched, because even if the
  // component doesn't need to update, it needs to persist the instance on to
  // the next vnode so that it can be properly unmounted later.
  (u.patchFlag > 0 || i & 6) && // the EVENTS flag is only for hydration and if it is the only flag, the
  // vnode should not be considered dynamic due to handler caching.
  u.patchFlag !== 32 && fe.push(u), u;
}
const Ce = bo;
function bo(e, t = null, s = null, n = 0, r = null, i = !1) {
  if ((!e || e === Ai) && (e = ht), mo(e)) {
    const c = Je(
      e,
      t,
      !0
      /* mergeRef: true */
    );
    return s && Hs(c, s), dt > 0 && !i && fe && (c.shapeFlag & 6 ? fe[fe.indexOf(e)] = c : fe.push(c)), c.patchFlag |= -2, c;
  }
  if (To(e) && (e = e.__vccOpts), t) {
    t = yo(t);
    let { class: c, style: u } = t;
    c && !q(c) && (t.class = lt(c)), B(u) && (Gn(u) && !P(u) && (u = V({}, u)), t.style = Cs(u));
  }
  const o = q(e) ? 1 : Ri(e) ? 128 : po(e) ? 64 : B(e) ? 4 : A(e) ? 2 : 0;
  return ot(
    e,
    t,
    s,
    n,
    r,
    o,
    i,
    !0
  );
}
function yo(e) {
  return e ? Gn(e) || pr(e) ? V({}, e) : e : null;
}
function Je(e, t, s = !1, n = !1) {
  const { props: r, ref: i, patchFlag: o, children: c, transition: u } = e, a = t ? Er(r || {}, t) : r, d = {
    __v_isVNode: !0,
    __v_skip: !0,
    type: e.type,
    props: a,
    key: a && Cr(a),
    ref: t && t.ref ? (
      // #2078 in the case of <component :is="vnode" ref="extra"/>
      // if the vnode itself already has a ref, cloneVNode will need to merge
      // the refs so the single vnode can be set on multiple refs
      s && i ? P(i) ? i.concat(Rt(t)) : [i, Rt(t)] : Rt(t)
    ) : i,
    scopeId: e.scopeId,
    slotScopeIds: e.slotScopeIds,
    children: c,
    target: e.target,
    targetAnchor: e.targetAnchor,
    staticCount: e.staticCount,
    shapeFlag: e.shapeFlag,
    // if the vnode is cloned with extra props, we can no longer assume its
    // existing patch flag to be reliable and need to add the FULL_PROPS flag.
    // note: preserve flag for fragments since they use the flag for children
    // fast paths only.
    patchFlag: t && e.type !== ge ? o === -1 ? 16 : o | 16 : o,
    dynamicProps: e.dynamicProps,
    dynamicChildren: e.dynamicChildren,
    appContext: e.appContext,
    dirs: e.dirs,
    transition: u,
    // These should technically only be non-null on mounted VNodes. However,
    // they *should* be copied for kept-alive vnodes. So we just always copy
    // them since them being non-null during a mount doesn't affect the logic as
    // they will simply be overwritten.
    component: e.component,
    suspense: e.suspense,
    ssContent: e.ssContent && Je(e.ssContent),
    ssFallback: e.ssFallback && Je(e.ssFallback),
    el: e.el,
    anchor: e.anchor,
    ctx: e.ctx,
    ce: e.ce
  };
  return u && n && (d.transition = u.clone(d)), d;
}
function xo(e = " ", t = 0) {
  return Ce(Vt, null, e, t);
}
function _e(e) {
  return e == null || typeof e == "boolean" ? Ce(ht) : P(e) ? Ce(
    ge,
    null,
    // #3666, avoid reference pollution when reusing vnode
    e.slice()
  ) : typeof e == "object" ? Se(e) : Ce(Vt, null, String(e));
}
function Se(e) {
  return e.el === null && e.patchFlag !== -1 || e.memo ? e : Je(e);
}
function Hs(e, t) {
  let s = 0;
  const { shapeFlag: n } = e;
  if (t == null)
    t = null;
  else if (P(t))
    s = 16;
  else if (typeof t == "object")
    if (n & 65) {
      const r = t.default;
      r && (r._c && (r._d = !1), Hs(e, r()), r._c && (r._d = !0));
      return;
    } else {
      s = 32;
      const r = t._;
      !r && !pr(t) ? t._ctx = be : r === 3 && be && (be.slots._ === 1 ? t._ = 1 : (t._ = 2, e.patchFlag |= 1024));
    }
  else
    A(t) ? (t = { default: t, _ctx: be }, s = 32) : (t = String(t), n & 64 ? (s = 16, t = [xo(t)]) : s = 8);
  e.children = t, e.shapeFlag |= s;
}
function Er(...e) {
  const t = {};
  for (let s = 0; s < e.length; s++) {
    const n = e[s];
    for (const r in n)
      if (r === "class")
        t.class !== n.class && (t.class = lt([t.class, n.class]));
      else if (r === "style")
        t.style = Cs([t.style, n.style]);
      else if (jt(r)) {
        const i = t[r], o = n[r];
        o && i !== o && !(P(i) && i.includes(o)) && (t[r] = i ? [].concat(i, o) : o);
      } else
        r !== "" && (t[r] = n[r]);
  }
  return t;
}
function pe(e, t, s, n = null) {
  ue(e, t, 7, [
    s,
    n
  ]);
}
const wo = ar();
let vo = 0;
function Co(e, t, s) {
  const n = e.type, r = (t ? t.appContext : e.appContext) || wo, i = {
    uid: vo++,
    vnode: e,
    type: n,
    parent: t,
    appContext: r,
    root: null,
    // to be immediately set
    next: null,
    subTree: null,
    // will be set synchronously right after creation
    effect: null,
    update: null,
    // will be set synchronously right after creation
    scope: new Vr(
      !0
      /* detached */
    ),
    render: null,
    proxy: null,
    exposed: null,
    exposeProxy: null,
    withProxy: null,
    provides: t ? t.provides : Object.create(r.provides),
    accessCache: null,
    renderCache: [],
    // local resolved assets
    components: null,
    directives: null,
    // resolved props and emits options
    propsOptions: _r(n, r),
    emitsOptions: rr(n, r),
    // emit
    emit: null,
    // to be set immediately
    emitted: null,
    // props default value
    propsDefaults: U,
    // inheritAttrs
    inheritAttrs: n.inheritAttrs,
    // state
    ctx: U,
    data: U,
    props: U,
    attrs: U,
    slots: U,
    refs: U,
    setupState: U,
    setupContext: null,
    attrsProxy: null,
    slotsProxy: null,
    // suspense related
    suspense: s,
    suspenseId: s ? s.pendingId : 0,
    asyncDep: null,
    asyncResolved: !1,
    // lifecycle hooks
    // not using enums here because it results in computed properties
    isMounted: !1,
    isUnmounted: !1,
    isDeactivated: !1,
    bc: null,
    c: null,
    bm: null,
    m: null,
    bu: null,
    u: null,
    um: null,
    bum: null,
    da: null,
    a: null,
    rtg: null,
    rtc: null,
    ec: null,
    sp: null
  };
  return i.ctx = { _: i }, i.root = t ? t.root : i, i.emit = Ci.bind(null, i), e.ce && e.ce(i), i;
}
let X = null, $t, bs;
{
  const e = Nn(), t = (s, n) => {
    let r;
    return (r = e[s]) || (r = e[s] = []), r.push(n), (i) => {
      r.length > 1 ? r.forEach((o) => o(i)) : r[0](i);
    };
  };
  $t = t(
    "__VUE_INSTANCE_SETTERS__",
    (s) => X = s
  ), bs = t(
    "__VUE_SSR_SETTERS__",
    (s) => qt = s
  );
}
const pt = (e) => {
  const t = X;
  return $t(e), e.scope.on(), () => {
    e.scope.off(), $t(t);
  };
}, pn = () => {
  X && X.scope.off(), $t(null);
};
function Or(e) {
  return e.vnode.shapeFlag & 4;
}
let qt = !1;
function Eo(e, t = !1) {
  t && bs(t);
  const { props: s, children: n } = e.vnode, r = Or(e);
  ro(e, s, r, t), lo(e, n);
  const i = r ? Oo(e, t) : void 0;
  return t && bs(!1), i;
}
function Oo(e, t) {
  const s = e.type;
  e.accessCache = /* @__PURE__ */ Object.create(null), e.proxy = new Proxy(e.ctx, Ji);
  const { setup: n } = s;
  if (n) {
    const r = e.setupContext = n.length > 1 ? So(e) : null, i = pt(e);
    Me();
    const o = Re(
      n,
      e,
      0,
      [
        e.props,
        r
      ]
    );
    if (Ne(), i(), Tn(o)) {
      if (o.then(pn, pn), t)
        return o.then((c) => {
          gn(e, c, t);
        }).catch((c) => {
          Ut(c, e, 0);
        });
      e.asyncDep = o;
    } else
      gn(e, o, t);
  } else
    Pr(e, t);
}
function gn(e, t, s) {
  A(t) ? e.type.__ssrInlineRender ? e.ssrRender = t : e.render = t : B(t) && (e.setupState = Xn(t)), Pr(e, s);
}
let _n;
function Pr(e, t, s) {
  const n = e.type;
  if (!e.render) {
    if (!t && _n && !n.render) {
      const r = n.template || js(e).template;
      if (r) {
        const { isCustomElement: i, compilerOptions: o } = e.appContext.config, { delimiters: c, compilerOptions: u } = n, a = V(
          V(
            {
              isCustomElement: i,
              delimiters: c
            },
            o
          ),
          u
        );
        n.render = _n(r, a);
      }
    }
    e.render = n.render || oe;
  }
  {
    const r = pt(e);
    Me();
    try {
      Yi(e);
    } finally {
      Ne(), r();
    }
  }
}
const Po = {
  get(e, t) {
    return ee(e, "get", ""), e[t];
  }
};
function So(e) {
  const t = (s) => {
    e.exposed = s || {};
  };
  return {
    attrs: new Proxy(e.attrs, Po),
    slots: e.slots,
    emit: e.emit,
    expose: t
  };
}
function Ks(e) {
  if (e.exposed)
    return e.exposeProxy || (e.exposeProxy = new Proxy(Xn(hi(e.exposed)), {
      get(t, s) {
        if (s in t)
          return t[s];
        if (s in nt)
          return nt[s](e);
      },
      has(t, s) {
        return s in t || s in nt;
      }
    }));
}
function To(e) {
  return A(e) && "__vccOpts" in e;
}
const Sr = (e, t) => di(e, t, qt), Ao = "3.4.27";
/**
* @vue/runtime-dom v3.4.27
* (c) 2018-present Yuxi (Evan) You and Vue contributors
* @license MIT
**/
const Ro = "http://www.w3.org/2000/svg", Io = "http://www.w3.org/1998/Math/MathML", Te = typeof document < "u" ? document : null, mn = Te && /* @__PURE__ */ Te.createElement("template"), Mo = {
  insert: (e, t, s) => {
    t.insertBefore(e, s || null);
  },
  remove: (e) => {
    const t = e.parentNode;
    t && t.removeChild(e);
  },
  createElement: (e, t, s, n) => {
    const r = t === "svg" ? Te.createElementNS(Ro, e) : t === "mathml" ? Te.createElementNS(Io, e) : Te.createElement(e, s ? { is: s } : void 0);
    return e === "select" && n && n.multiple != null && r.setAttribute("multiple", n.multiple), r;
  },
  createText: (e) => Te.createTextNode(e),
  createComment: (e) => Te.createComment(e),
  setText: (e, t) => {
    e.nodeValue = t;
  },
  setElementText: (e, t) => {
    e.textContent = t;
  },
  parentNode: (e) => e.parentNode,
  nextSibling: (e) => e.nextSibling,
  querySelector: (e) => Te.querySelector(e),
  setScopeId(e, t) {
    e.setAttribute(t, "");
  },
  // __UNSAFE__
  // Reason: innerHTML.
  // Static content here can only come from compiled templates.
  // As long as the user only uses trusted templates, this is safe.
  insertStaticContent(e, t, s, n, r, i) {
    const o = s ? s.previousSibling : t.lastChild;
    if (r && (r === i || r.nextSibling))
      for (; t.insertBefore(r.cloneNode(!0), s), !(r === i || !(r = r.nextSibling)); )
        ;
    else {
      mn.innerHTML = n === "svg" ? `<svg>${e}</svg>` : n === "mathml" ? `<math>${e}</math>` : e;
      const c = mn.content;
      if (n === "svg" || n === "mathml") {
        const u = c.firstChild;
        for (; u.firstChild; )
          c.appendChild(u.firstChild);
        c.removeChild(u);
      }
      t.insertBefore(c, s);
    }
    return [
      // first
      o ? o.nextSibling : t.firstChild,
      // last
      s ? s.previousSibling : t.lastChild
    ];
  }
}, No = Symbol("_vtc");
function Fo(e, t, s) {
  const n = e[No];
  n && (t = (t ? [t, ...n] : [...n]).join(" ")), t == null ? e.removeAttribute("class") : s ? e.setAttribute("class", t) : e.className = t;
}
const bn = Symbol("_vod"), $o = Symbol("_vsh"), jo = Symbol(""), Lo = /(^|;)\s*display\s*:/;
function Ho(e, t, s) {
  const n = e.style, r = q(s);
  let i = !1;
  if (s && !r) {
    if (t)
      if (q(t))
        for (const o of t.split(";")) {
          const c = o.slice(0, o.indexOf(":")).trim();
          s[c] == null && It(n, c, "");
        }
      else
        for (const o in t)
          s[o] == null && It(n, o, "");
    for (const o in s)
      o === "display" && (i = !0), It(n, o, s[o]);
  } else if (r) {
    if (t !== s) {
      const o = n[jo];
      o && (s += ";" + o), n.cssText = s, i = Lo.test(s);
    }
  } else
    t && e.removeAttribute("style");
  bn in e && (e[bn] = i ? n.display : "", e[$o] && (n.display = "none"));
}
const yn = /\s*!important$/;
function It(e, t, s) {
  if (P(s))
    s.forEach((n) => It(e, t, n));
  else if (s == null && (s = ""), t.startsWith("--"))
    e.setProperty(t, s);
  else {
    const n = Ko(e, t);
    yn.test(s) ? e.setProperty(
      ie(n),
      s.replace(yn, ""),
      "important"
    ) : e[n] = s;
  }
}
const xn = ["Webkit", "Moz", "ms"], rs = {};
function Ko(e, t) {
  const s = rs[t];
  if (s)
    return s;
  let n = we(t);
  if (n !== "filter" && n in e)
    return rs[t] = n;
  n = In(n);
  for (let r = 0; r < xn.length; r++) {
    const i = xn[r] + n;
    if (i in e)
      return rs[t] = i;
  }
  return t;
}
const wn = "http://www.w3.org/1999/xlink";
function Uo(e, t, s, n, r) {
  if (n && t.startsWith("xlink:"))
    s == null ? e.removeAttributeNS(wn, t.slice(6, t.length)) : e.setAttributeNS(wn, t, s);
  else {
    const i = Dr(t);
    s == null || i && !Fn(s) ? e.removeAttribute(t) : e.setAttribute(t, i ? "" : s);
  }
}
function Do(e, t, s, n, r, i, o) {
  if (t === "innerHTML" || t === "textContent") {
    n && o(n, r, i), e[t] = s ?? "";
    return;
  }
  const c = e.tagName;
  if (t === "value" && c !== "PROGRESS" && // custom elements may use _value internally
  !c.includes("-")) {
    const a = c === "OPTION" ? e.getAttribute("value") || "" : e.value, d = s ?? "";
    (a !== d || !("_value" in e)) && (e.value = d), s == null && e.removeAttribute(t), e._value = s;
    return;
  }
  let u = !1;
  if (s === "" || s == null) {
    const a = typeof e[t];
    a === "boolean" ? s = Fn(s) : s == null && a === "string" ? (s = "", u = !0) : a === "number" && (s = 0, u = !0);
  }
  try {
    e[t] = s;
  } catch {
  }
  u && e.removeAttribute(t);
}
function Bo(e, t, s, n) {
  e.addEventListener(t, s, n);
}
function Vo(e, t, s, n) {
  e.removeEventListener(t, s, n);
}
const vn = Symbol("_vei");
function qo(e, t, s, n, r = null) {
  const i = e[vn] || (e[vn] = {}), o = i[t];
  if (n && o)
    o.value = n;
  else {
    const [c, u] = Wo(t);
    if (n) {
      const a = i[t] = Go(
        n,
        r
      );
      Bo(e, c, a, u);
    } else
      o && (Vo(e, c, o, u), i[t] = void 0);
  }
}
const Cn = /(?:Once|Passive|Capture)$/;
function Wo(e) {
  let t;
  if (Cn.test(e)) {
    t = {};
    let n;
    for (; n = e.match(Cn); )
      e = e.slice(0, e.length - n[0].length), t[n[0].toLowerCase()] = !0;
  }
  return [e[2] === ":" ? e.slice(3) : ie(e.slice(2)), t];
}
let is = 0;
const ko = /* @__PURE__ */ Promise.resolve(), zo = () => is || (ko.then(() => is = 0), is = Date.now());
function Go(e, t) {
  const s = (n) => {
    if (!n._vts)
      n._vts = Date.now();
    else if (n._vts <= s.attached)
      return;
    ue(
      Jo(n, s.value),
      t,
      5,
      [n]
    );
  };
  return s.value = e, s.attached = zo(), s;
}
function Jo(e, t) {
  if (P(t)) {
    const s = e.stopImmediatePropagation;
    return e.stopImmediatePropagation = () => {
      s.call(e), e._stopped = !0;
    }, t.map(
      (n) => (r) => !r._stopped && n && n(r)
    );
  } else
    return t;
}
const En = (e) => e.charCodeAt(0) === 111 && e.charCodeAt(1) === 110 && // lowercase letter
e.charCodeAt(2) > 96 && e.charCodeAt(2) < 123, Yo = (e, t, s, n, r, i, o, c, u) => {
  const a = r === "svg";
  t === "class" ? Fo(e, n, a) : t === "style" ? Ho(e, s, n) : jt(t) ? xs(t) || qo(e, t, s, n, o) : (t[0] === "." ? (t = t.slice(1), !0) : t[0] === "^" ? (t = t.slice(1), !1) : Qo(e, t, n, a)) ? Do(
    e,
    t,
    n,
    i,
    o,
    c,
    u
  ) : (t === "true-value" ? e._trueValue = n : t === "false-value" && (e._falseValue = n), Uo(e, t, n, a));
};
function Qo(e, t, s, n) {
  if (n)
    return !!(t === "innerHTML" || t === "textContent" || t in e && En(t) && A(s));
  if (t === "spellcheck" || t === "draggable" || t === "translate" || t === "form" || t === "list" && e.tagName === "INPUT" || t === "type" && e.tagName === "TEXTAREA")
    return !1;
  if (t === "width" || t === "height") {
    const r = e.tagName;
    if (r === "IMG" || r === "VIDEO" || r === "CANVAS" || r === "SOURCE")
      return !1;
  }
  return En(t) && q(s) ? !1 : t in e;
}
/*! #__NO_SIDE_EFFECTS__ */
// @__NO_SIDE_EFFECTS__
function Xo(e, t) {
  const s = /* @__PURE__ */ $s(e);
  class n extends Us {
    constructor(i) {
      super(s, i, t);
    }
  }
  return n.def = s, n;
}
const Zo = typeof HTMLElement < "u" ? HTMLElement : class {
};
class Us extends Zo {
  constructor(t, s = {}, n) {
    super(), this._def = t, this._props = s, this._instance = null, this._connected = !1, this._resolved = !1, this._numberProps = null, this._ob = null, this.shadowRoot && n ? n(this._createVNode(), this.shadowRoot) : (this.attachShadow({ mode: "open" }), this._def.__asyncLoader || this._resolveProps(this._def));
  }
  connectedCallback() {
    this._connected = !0, this._instance || (this._resolved ? this._update() : this._resolveDef());
  }
  disconnectedCallback() {
    this._connected = !1, this._ob && (this._ob.disconnect(), this._ob = null), er(() => {
      this._connected || (Pn(null, this.shadowRoot), this._instance = null);
    });
  }
  /**
   * resolve inner component definition (handle possible async component)
   */
  _resolveDef() {
    this._resolved = !0;
    for (let n = 0; n < this.attributes.length; n++)
      this._setAttr(this.attributes[n].name);
    this._ob = new MutationObserver((n) => {
      for (const r of n)
        this._setAttr(r.attributeName);
    }), this._ob.observe(this, { attributes: !0 });
    const t = (n, r = !1) => {
      const { props: i, styles: o } = n;
      let c;
      if (i && !P(i))
        for (const u in i) {
          const a = i[u];
          (a === Number || a && a.type === Number) && (u in this._props && (this._props[u] = zs(this._props[u])), (c || (c = /* @__PURE__ */ Object.create(null)))[we(u)] = !0);
        }
      this._numberProps = c, r && this._resolveProps(n), this._applyStyles(o), this._update();
    }, s = this._def.__asyncLoader;
    s ? s().then((n) => t(n, !0)) : t(this._def);
  }
  _resolveProps(t) {
    const { props: s } = t, n = P(s) ? s : Object.keys(s || {});
    for (const r of Object.keys(this))
      r[0] !== "_" && n.includes(r) && this._setProp(r, this[r], !0, !1);
    for (const r of n.map(we))
      Object.defineProperty(this, r, {
        get() {
          return this._getProp(r);
        },
        set(i) {
          this._setProp(r, i);
        }
      });
  }
  _setAttr(t) {
    let s = this.hasAttribute(t) ? this.getAttribute(t) : void 0;
    const n = we(t);
    this._numberProps && this._numberProps[n] && (s = zs(s)), this._setProp(n, s, !1);
  }
  /**
   * @internal
   */
  _getProp(t) {
    return this._props[t];
  }
  /**
   * @internal
   */
  _setProp(t, s, n = !0, r = !0) {
    s !== this._props[t] && (this._props[t] = s, r && this._instance && this._update(), n && (s === !0 ? this.setAttribute(ie(t), "") : typeof s == "string" || typeof s == "number" ? this.setAttribute(ie(t), s + "") : s || this.removeAttribute(ie(t))));
  }
  _update() {
    Pn(this._createVNode(), this.shadowRoot);
  }
  _createVNode() {
    const t = Ce(this._def, V({}, this._props));
    return this._instance || (t.ce = (s) => {
      this._instance = s, s.isCE = !0;
      const n = (i, o) => {
        this.dispatchEvent(
          new CustomEvent(i, {
            detail: o
          })
        );
      };
      s.emit = (i, ...o) => {
        n(i, o), ie(i) !== i && n(ie(i), o);
      };
      let r = this;
      for (; r = r && (r.parentNode || r.host); )
        if (r instanceof Us) {
          s.parent = r._instance, s.provides = r._instance.provides;
          break;
        }
    }), t;
  }
  _applyStyles(t) {
    t && t.forEach((s) => {
      const n = document.createElement("style");
      n.textContent = s, this.shadowRoot.appendChild(n);
    });
  }
}
const el = ["ctrl", "shift", "alt", "meta"], tl = {
  stop: (e) => e.stopPropagation(),
  prevent: (e) => e.preventDefault(),
  self: (e) => e.target !== e.currentTarget,
  ctrl: (e) => !e.ctrlKey,
  shift: (e) => !e.shiftKey,
  alt: (e) => !e.altKey,
  meta: (e) => !e.metaKey,
  left: (e) => "button" in e && e.button !== 0,
  middle: (e) => "button" in e && e.button !== 1,
  right: (e) => "button" in e && e.button !== 2,
  exact: (e, t) => el.some((s) => e[`${s}Key`] && !t.includes(s))
}, Ot = (e, t) => {
  const s = e._withMods || (e._withMods = {}), n = t.join(".");
  return s[n] || (s[n] = (r, ...i) => {
    for (let o = 0; o < t.length; o++) {
      const c = tl[t[o]];
      if (c && c(r, t))
        return;
    }
    return e(r, ...i);
  });
}, sl = {
  esc: "escape",
  space: " ",
  up: "arrow-up",
  left: "arrow-left",
  right: "arrow-right",
  down: "arrow-down",
  delete: "backspace"
}, os = (e, t) => {
  const s = e._withKeys || (e._withKeys = {}), n = t.join(".");
  return s[n] || (s[n] = (r) => {
    if (!("key" in r))
      return;
    const i = ie(r.key);
    if (t.some((o) => o === i || sl[o] === i))
      return e(r);
  });
}, nl = /* @__PURE__ */ V({ patchProp: Yo }, Mo);
let On;
function rl() {
  return On || (On = fo(nl));
}
const Pn = (...e) => {
  rl().render(...e);
};
function il(e, t = ol) {
  const s = [...e];
  for (let n = s.length - 1; n > 0; n--) {
    const r = t() % (n + 1);
    [s[n], s[r]] = [s[r], s[n]];
  }
  return s;
}
function ol() {
  return Math.floor(Math.random() * 2 ** 32);
}
const ll = ["aria-checked", "tabindex"], cl = /* @__PURE__ */ $s({
  __name: "QCUChoice",
  props: {
    choice: {},
    maxChoicesPerLine: {},
    imageClass: {},
    nameClass: {},
    checked: { type: Boolean },
    focusable: { type: Boolean }
  },
  emits: ["check", "checkNext", "checkPrevious"],
  setup(e) {
    const t = e, s = pi();
    Fi(() => {
      var r;
      t.checked && ((r = s.value) == null || r.focus());
    });
    const n = `qcu-image-choice-${(t.choice.response ?? t.choice.name).replace(/[^\w\-_:.]/g, "")}`;
    return (r, i) => (At(), ms("li", {
      ref_key: "el",
      ref: s,
      role: "radio",
      class: lt(["qcu-image__choice", `qcu-image__choice--max${t.maxChoicesPerLine}`]),
      "aria-labelledby": n,
      "aria-checked": r.checked,
      tabindex: r.focusable ? 0 : -1,
      onClick: i[0] || (i[0] = Ot((o) => r.$emit("check"), ["stop"])),
      onKeydown: [
        i[1] || (i[1] = os(Ot((o) => r.$emit("check"), ["stop", "prevent"]), ["enter", "space"])),
        i[2] || (i[2] = os(Ot((o) => r.$emit("checkPrevious"), ["stop", "prevent"]), ["up", "left"])),
        i[3] || (i[3] = os(Ot((o) => r.$emit("checkNext"), ["stop", "prevent"]), ["down", "right"]))
      ]
    }, [
      ot("figure", null, [
        ot("img", Er(r.choice.image, {
          class: r.imageClass,
          alt: ""
        }), null, 16),
        ot("figcaption", {
          class: lt(r.nameClass),
          id: n
        }, Br(r.choice.name), 3)
      ])
    ], 42, ll));
  }
}), fl = ["aria-label"], ul = /* @__PURE__ */ $s({
  __name: "QCUImage.ce",
  props: {
    props: { type: Object }
  },
  emits: ["answer"],
  setup(e, { emit: t }) {
    const s = e, n = t, r = s.props.maxChoicesPerLine ?? 5, i = s.props.hideChoicesName ? "sr-only" : "", o = s.props.imageChoicesSize ?? "icon", c = il(s.props.choices), u = gi(), a = Sr(() => u.value ?? c[0]);
    function d(y) {
      y === -1 ? u.value = c[c.length - 1] : y === c.length ? u.value = c[0] : u.value = c[y], n("answer", u.value.response ?? u.value.name);
    }
    return (y, C) => (At(), ms("ul", {
      role: "radiogroup",
      class: "qcu-image",
      "aria-label": s.props.name
    }, [
      (At(!0), ms(ge, null, Gi(Ze(c), (S, j) => (At(), _o(cl, {
        choice: S,
        "max-choices-per-line": Ze(r),
        key: j,
        "name-class": Ze(i),
        "image-class": Ze(o),
        checked: S === u.value,
        focusable: S === a.value,
        onCheck: (M) => d(j),
        onCheckNext: (M) => d(j + 1),
        onCheckPrevious: (M) => d(j - 1)
      }, null, 8, ["choice", "max-choices-per-line", "name-class", "image-class", "checked", "focusable", "onCheck", "onCheckNext", "onCheckPrevious"]))), 128))
    ], 8, fl));
  }
}), al = '*{box-sizing:border-box}ul,li{padding:0;margin:0}li{list-style:none}.qcu-image{display:flex;width:100%;flex-wrap:wrap;justify-content:space-around;font-size:110%;font-family:Open Sans,Arial,sans-serif}.qcu-image__choice{display:flex;position:relative;flex-direction:column;align-items:center;justify-content:center;margin:1em;border:solid 2px #c1c7d0;border-radius:10px;background:#fff;width:calc(20% - 2em);min-width:120px}.qcu-image__choice--max4{width:calc(25% - 2em);min-width:175px}.qcu-image__choice--max3{width:calc(33% - 2em)}.qcu-image__choice--max2{width:calc(50% - 2em);min-width:300px}.qcu-image__choice--max1{width:calc(100% - 4em);min-width:300px}.qcu-image__choice:hover{cursor:pointer;box-shadow:0 3px 10px #0000001a;transform:scale(1.04);transition:transform ease-in .1s}.qcu-image__choice:focus{outline:none;box-shadow:5px 5px #ffbe00,-5px 5px #ffbe00,5px -5px #ffbe00,-5px -5px #ffbe00}.qcu-image__choice[aria-checked=true]{border-color:#3d68ff}.qcu-image__choice[aria-checked=true]:after{content:"";position:absolute;width:100%;height:100%;background:#3d68ff4d;border-radius:10px;z-index:3}.qcu-image__choice figure{width:100%;padding:0 10px;margin:3px;text-align:center}.qcu-image__choice figure img{max-width:100%;height:auto}.qcu-image__choice figure img.icon{max-height:100px;width:auto}.qcu-image__choice figure figcaption{margin:1ex}.sr-only{position:absolute;width:1px;height:1px;padding:0;margin:-1px;overflow:hidden;clip:rect(0,0,0,0);border:0}', hl = (e, t) => {
  const s = e.__vccOpts || e;
  for (const [n, r] of t)
    s[n] = r;
  return s;
}, dl = /* @__PURE__ */ hl(ul, [["styles", [al]]]), pl = /* @__PURE__ */ Xo(dl);
window.customElements.define("qcu-image", pl);
