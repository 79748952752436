import { macroCondition, getGlobalConfig } from '@embroider/macros';
const defaultRules = {
  plurals: [[/$/, 's'], [/s$/i, 's'], [/^(ax|test)is$/i, '$1es'], [/(octop|vir)us$/i, '$1i'], [/(octop|vir)i$/i, '$1i'], [/(alias|status|bonus)$/i, '$1es'], [/(bu)s$/i, '$1ses'], [/(buffal|tomat)o$/i, '$1oes'], [/([ti])um$/i, '$1a'], [/([ti])a$/i, '$1a'], [/sis$/i, 'ses'], [/(?:([^f])fe|([lr])f)$/i, '$1$2ves'], [/(hive)$/i, '$1s'], [/([^aeiouy]|qu)y$/i, '$1ies'], [/(x|ch|ss|sh)$/i, '$1es'], [/(matr|vert|ind)(?:ix|ex)$/i, '$1ices'], [/^(m|l)ouse$/i, '$1ice'], [/^(m|l)ice$/i, '$1ice'], [/^(ox)$/i, '$1en'], [/^(oxen)$/i, '$1'], [/(quiz)$/i, '$1zes']],
  singular: [[/s$/i, ''], [/(ss)$/i, '$1'], [/(n)ews$/i, '$1ews'], [/([ti])a$/i, '$1um'], [/((a)naly|(b)a|(d)iagno|(p)arenthe|(p)rogno|(s)ynop|(t)he)(sis|ses)$/i, '$1sis'], [/(^analy)(sis|ses)$/i, '$1sis'], [/([^f])ves$/i, '$1fe'], [/(hive)s$/i, '$1'], [/(tive)s$/i, '$1'], [/([lr])ves$/i, '$1f'], [/([^aeiouy]|qu)ies$/i, '$1y'], [/(s)eries$/i, '$1eries'], [/(m)ovies$/i, '$1ovie'], [/(x|ch|ss|sh)es$/i, '$1'], [/^(m|l)ice$/i, '$1ouse'], [/(bus)(es)?$/i, '$1'], [/(o)es$/i, '$1'], [/(shoe)s$/i, '$1'], [/(cris|test)(is|es)$/i, '$1is'], [/^(a)x[ie]s$/i, '$1xis'], [/(octop|vir)(us|i)$/i, '$1us'], [/(alias|status|bonus)(es)?$/i, '$1'], [/^(ox)en/i, '$1'], [/(vert|ind)ices$/i, '$1ex'], [/(matr)ices$/i, '$1ix'], [/(quiz)zes$/i, '$1'], [/(database)s$/i, '$1']],
  irregularPairs: [['person', 'people'], ['man', 'men'], ['child', 'children'], ['sex', 'sexes'], ['move', 'moves'], ['cow', 'kine'], ['zombie', 'zombies']],
  uncountable: ['equipment', 'information', 'rice', 'money', 'species', 'series', 'fish', 'sheep', 'jeans', 'police']
};
const DEFAULT_MAX_CACHE_SIZE = 10_000;
class LRUCache {
  // debug stats

  constructor(doWork, size) {
    this.size = size || DEFAULT_MAX_CACHE_SIZE;
    this.state = new Map();
    this.doWork = doWork;
    if (macroCondition(getGlobalConfig().WarpDrive.env.DEBUG)) {
      this._hits = 0;
      this._misses = 0;
      this._ejected = 0;
    }
  }
  get(key) {
    const value = this.state.get(key);
    if (value) {
      if (macroCondition(getGlobalConfig().WarpDrive.env.DEBUG)) {
        this._hits++;
      }
      this.state.delete(key);
      this.state.set(key, value);
      return value;
    }
    if (macroCondition(getGlobalConfig().WarpDrive.env.DEBUG)) {
      this._misses++;
    }
    const newValue = this.doWork(key);
    this.set(key, newValue);
    return newValue;
  }
  set(key, value) {
    if (this.state.size === this.size) {
      for (const [k] of this.state) {
        if (macroCondition(getGlobalConfig().WarpDrive.env.DEBUG)) {
          this._ejected++;
        }
        this.state.delete(k);
        break;
      }
    }
    this.state.set(key, value);
  }
  clear() {
    this.state.clear();
    if (macroCondition(getGlobalConfig().WarpDrive.env.DEBUG)) {
      this._hits = 0;
      this._misses = 0;
      this._ejected = 0;
    }
  }
}
const STRING_DASHERIZE_REGEXP = /[ _]/g;
const STRING_DECAMELIZE_REGEXP = /([a-z\d])([A-Z])/g;
const STRING_DASHERIZE_CACHE = new LRUCache(key => key.replace(STRING_DECAMELIZE_REGEXP, '$1_$2').toLowerCase().replace(STRING_DASHERIZE_REGEXP, '-'));

// eslint-disable-next-line no-useless-escape
const STRING_CAMELIZE_REGEXP_1 = /(\-|\_|\.|\s)+(.)?/g;
const STRING_CAMELIZE_REGEXP_2 = /(^|\/)([A-Z])/g;
const CAMELIZE_CACHE = new LRUCache(key => key.replace(STRING_CAMELIZE_REGEXP_1, (_match, _separator, chr) => chr ? chr.toUpperCase() : '').replace(STRING_CAMELIZE_REGEXP_2, (match /*, separator, chr */) => match.toLowerCase()));
const STRING_UNDERSCORE_REGEXP_1 = /([a-z\d])([A-Z]+)/g;
// eslint-disable-next-line no-useless-escape
const STRING_UNDERSCORE_REGEXP_2 = /\-|\s+/g;
const UNDERSCORE_CACHE = new LRUCache(str => str.replace(STRING_UNDERSCORE_REGEXP_1, '$1_$2').replace(STRING_UNDERSCORE_REGEXP_2, '_').toLowerCase());
const STRING_CAPITALIZE_REGEXP = /(^|\/)([a-z\u00C0-\u024F])/g;
const CAPITALIZE_CACHE = new LRUCache(str => str.replace(STRING_CAPITALIZE_REGEXP, (match /*, separator, chr */) => match.toUpperCase()));

/**
  Replaces underscores, spaces, or camelCase with dashes.

  ```js
  import { dasherize } from '@ember-data/request-utils/string';

  dasherize('innerHTML');                // 'inner-html'
  dasherize('action_name');              // 'action-name'
  dasherize('css-class-name');           // 'css-class-name'
  dasherize('my favorite items');        // 'my-favorite-items'
  dasherize('privateDocs/ownerInvoice';  // 'private-docs/owner-invoice'
  ```

  @typedoc
*/
function dasherize(str) {
  return STRING_DASHERIZE_CACHE.get(str);
}

/**
  Returns the lowerCamelCase form of a string.

  ```js
  import { camelize } from '@ember-data/request-utils/string';

  camelize('innerHTML');                   // 'innerHTML'
  camelize('action_name');                 // 'actionName'
  camelize('css-class-name');              // 'cssClassName'
  camelize('my favorite items');           // 'myFavoriteItems'
  camelize('My Favorite Items');           // 'myFavoriteItems'
  camelize('private-docs/owner-invoice');  // 'privateDocs/ownerInvoice'
```

  @typedoc
*/
function camelize(str) {
  return CAMELIZE_CACHE.get(str);
}

/**
  Returns the lower\_case\_and\_underscored form of a string.

  ```js
  import { underscore } from '@ember-data/request-utils/string';

  underscore('innerHTML');                 // 'inner_html'
  underscore('action_name');               // 'action_name'
  underscore('css-class-name');            // 'css_class_name'
  underscore('my favorite items');         // 'my_favorite_items'
  underscore('privateDocs/ownerInvoice');  // 'private_docs/owner_invoice'
  ```

  @typedoc
*/
function underscore(str) {
  return UNDERSCORE_CACHE.get(str);
}

/**
  Returns the Capitalized form of a string

  ```js
  import { capitalize } from '@ember-data/request-utils/string';

  capitalize('innerHTML')                 // 'InnerHTML'
  capitalize('action_name')               // 'Action_name'
  capitalize('css-class-name')            // 'Css-class-name'
  capitalize('my favorite items')         // 'My favorite items'
  capitalize('privateDocs/ownerInvoice'); // 'PrivateDocs/ownerInvoice'
  ```

  @typedoc
*/
function capitalize(str) {
  return CAPITALIZE_CACHE.get(str);
}
function setMaxLRUCacheSize(size) {
  CAMELIZE_CACHE.size = size;
  UNDERSCORE_CACHE.size = size;
  CAPITALIZE_CACHE.size = size;
  STRING_DASHERIZE_CACHE.size = size;
}
const BLANK_REGEX = /^\s*$/;
const LAST_WORD_DASHED_REGEX = /([\w/-]+[_/\s-])([a-z\d]+$)/;
const LAST_WORD_CAMELIZED_REGEX = /([\w/\s-]+)([A-Z][a-z\d]*$)/;
const CAMELIZED_REGEX = /[A-Z][a-z\d]*$/;
const SINGULARS = new LRUCache(word => {
  return _singularize(word);
});
const PLURALS = new LRUCache(word => {
  return _pluralize(word);
});
const UNCOUNTABLE = new Set(defaultRules.uncountable);
const IRREGULAR = new Map();
const INVERSE_IRREGULAR = new Map();
const SINGULAR_RULES = new Map(defaultRules.singular.reverse());
const PLURAL_RULES = new Map(defaultRules.plurals.reverse());
function uncountable(word) {
  UNCOUNTABLE.add(word.toLowerCase());
}
function loadUncountable(uncountables) {
  uncountables.forEach(word => {
    uncountable(word);
  });
}
function irregular(single, plur) {
  //pluralizing
  IRREGULAR.set(single.toLowerCase(), plur);
  IRREGULAR.set(plur.toLowerCase(), plur);

  //singularizing
  INVERSE_IRREGULAR.set(plur.toLowerCase(), single);
  INVERSE_IRREGULAR.set(single.toLowerCase(), single);
}
function loadIrregular(irregularPairs) {
  irregularPairs.forEach(pair => {
    //pluralizing
    IRREGULAR.set(pair[0].toLowerCase(), pair[1]);
    IRREGULAR.set(pair[1].toLowerCase(), pair[1]);

    //singularizing
    INVERSE_IRREGULAR.set(pair[1].toLowerCase(), pair[0]);
    INVERSE_IRREGULAR.set(pair[0].toLowerCase(), pair[0]);
  });
}
loadIrregular(defaultRules.irregularPairs);
function clear() {
  SINGULARS.clear();
  PLURALS.clear();
}
function resetToDefaults() {
  clearRules();
  defaultRules.uncountable.forEach(v => UNCOUNTABLE.add(v));
  defaultRules.singular.forEach(v => SINGULAR_RULES.set(v[0], v[1]));
  defaultRules.plurals.forEach(v => PLURAL_RULES.set(v[0], v[1]));
  loadIrregular(defaultRules.irregularPairs);
}
function clearRules() {
  SINGULARS.clear();
  PLURALS.clear();
  UNCOUNTABLE.clear();
  IRREGULAR.clear();
  INVERSE_IRREGULAR.clear();
  SINGULAR_RULES.clear();
  PLURAL_RULES.clear();
}
function singularize(word) {
  macroCondition(getGlobalConfig().WarpDrive.env.DEBUG) ? (test => {
    if (!test) {
      throw new Error(`singularize expects to receive a non-empty string`);
    }
  })(typeof word === 'string' && word.length > 0) : {};
  if (!word) return '';
  return SINGULARS.get(word);
}
function pluralize(word) {
  macroCondition(getGlobalConfig().WarpDrive.env.DEBUG) ? (test => {
    if (!test) {
      throw new Error(`pluralize expects to receive a non-empty string`);
    }
  })(typeof word === 'string' && word.length > 0) : {};
  if (!word) return '';
  return PLURALS.get(word);
}
function unshiftMap(v, map) {
  // reorder
  const rules = [v, ...map.entries()];
  map.clear();
  rules.forEach(rule => {
    map.set(rule[0], rule[1]);
  });
}
function plural(regex, string) {
  // rule requires reordering if exists, so remove it first
  if (PLURAL_RULES.has(regex)) {
    PLURAL_RULES.delete(regex);
  }

  // reorder
  unshiftMap([regex, string], PLURAL_RULES);
}
function singular(regex, string) {
  // rule requires reordering if exists, so remove it first
  if (SINGULAR_RULES.has(regex)) {
    SINGULAR_RULES.delete(regex);
  }

  // reorder
  unshiftMap([regex, string], SINGULAR_RULES);
}
function _pluralize(word) {
  return inflect(word, PLURAL_RULES, IRREGULAR);
}
function _singularize(word) {
  return inflect(word, SINGULAR_RULES, INVERSE_IRREGULAR);
}
function inflect(word, typeRules, irregulars) {
  // empty strings
  const isBlank = !word || BLANK_REGEX.test(word);
  if (isBlank) {
    return word;
  }

  // basic uncountables
  const lowercase = word.toLowerCase();
  if (UNCOUNTABLE.has(lowercase)) {
    return word;
  }

  // adv uncountables
  const wordSplit = LAST_WORD_DASHED_REGEX.exec(word) || LAST_WORD_CAMELIZED_REGEX.exec(word);
  const lastWord = wordSplit ? wordSplit[2].toLowerCase() : null;
  if (lastWord && UNCOUNTABLE.has(lastWord)) {
    return word;
  }

  // handle irregulars
  const isCamelized = CAMELIZED_REGEX.test(word);
  for (let [rule, substitution] of irregulars) {
    if (lowercase.match(rule + '$')) {
      if (isCamelized && lastWord && irregulars.has(lastWord)) {
        substitution = capitalize(substitution);
        rule = capitalize(rule);
      }
      return word.replace(new RegExp(rule, 'i'), substitution);
    }
  }

  // do the actual inflection
  for (const [rule, substitution] of typeRules) {
    if (rule.test(word)) {
      return word.replace(rule, substitution);
    }
  }
  return word;
}
export { singular as a, plural as b, loadUncountable as c, clear as d, clearRules as e, dasherize as f, camelize as g, capitalize as h, irregular as i, underscore as j, setMaxLRUCacheSize as k, loadIrregular as l, defaultRules as m, pluralize as p, resetToDefaults as r, singularize as s, uncountable as u };