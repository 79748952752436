import { template } from "@ember/template-compiler";
export default template(`
  <div class="challenge-layout challenge-layout--{{@color}}">
    <div class="container">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
