import { template } from "@ember/template-compiler";
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { or } from 'ember-truth-helpers';
import CardWrapper from '../card-wrapper';
import AutoReply from './content/auto-reply';
import ChallengeActions from './content/challenge-actions';
import ChallengeMedia from './content/challenge-media';
import EmbeddedSimulator from './content/embedded-simulator';
import EmbeddedWebComponent from './content/embedded-web-component';
import Qcm from './content/qcm';
import Qcu from './content/qcu';
import Qrocm from './content/qrocm';
export default class ChallengeContent extends Component {
    @tracked
    isRebootable = false;
    constructor(){
        super(...arguments);
        window.addEventListener('message', ({ data: data1 })=>{
            if (data1?.from === 'pix' && data1?.type === 'init') {
                this.isRebootable = !!data1.rebootable;
            }
        });
    }
    get shouldDisplayMultipleElements() {
        const challenge1 = this.args.challenge;
        const hasMediaAndForm1 = challenge1.hasForm && challenge1.hasMedia && challenge1.hasType;
        const hasIllustrationAndEmbed1 = challenge1.illustrationUrl && challenge1.hasEmbed;
        const hasIllustrationAndWebComponent1 = challenge1.illustrationUrl && challenge1.hasWebComponent;
        const hasFormAndEmbed1 = challenge1.hasForm && challenge1.hasEmbed;
        return hasMediaAndForm1 || hasIllustrationAndEmbed1 || hasIllustrationAndWebComponent1 || hasFormAndEmbed1;
    }
    get challengeContentClassname() {
        const hasIllustrationAndEmbed1 = this.args.challenge.illustrationUrl && this.args.challenge.hasEmbed;
        const hasEmbedAndForm1 = this.args.challenge.hasEmbed && this.args.challenge.hasForm;
        let classname1 = '';
        if (this.shouldDisplayMultipleElements) {
            classname1 = 'challenge-content__grid-multiple-element';
            if (hasIllustrationAndEmbed1) {
                classname1 += ' challenge-content__grid-multiple-element--40-60';
            }
            if (hasEmbedAndForm1) {
                classname1 += ' challenge-content__grid-multiple-element--60-40';
            }
        }
        return classname1;
    }
    get shouldDisplayRebootButton() {
        return this.isRebootable && !this.args.isDisabled;
    }
    static{
        template(`
    <div class="challenge-content {{this.challengeContentClassname}}">
      {{#if @challenge.illustrationUrl}}
        <CardWrapper>
          <ChallengeMedia @src={{@challenge.illustrationUrl}} @alt={{@challenge.illustrationAlt}} />
        </CardWrapper>
      {{/if}}
      {{#if @challenge.hasEmbed}}
        <CardWrapper>
          <EmbeddedSimulator
            @url={{@challenge.embedUrl}}
            @title={{@challenge.embedTitle}}
            @height={{@challenge.embedHeight}}
            @hideSimulator={{@isDisabled}}
            @isMediaWithForm={{this.isMediaWithForm}}
            @shouldDisplayRebootButton={{this.shouldDisplayRebootButton}}
          />
        </CardWrapper>
      {{/if}}
      {{#if @challenge.hasWebComponent}}
        <EmbeddedWebComponent
          @tagName={{@challenge.webComponentTagName}}
          @props={{@challenge.webComponentProps}}
          @setAnswerValue={{@setAnswerValue}}
        />
      {{/if}}
      {{#if @challenge.hasForm}}
        <div class="challenge-content__form">
          {{#if (or @challenge.isQROC @challenge.isQROCM)}}
            <Qrocm @challenge={{@challenge}} @setAnswerValue={{@setAnswerValue}} @isDisabled={{@isDisabled}} />
          {{else if @challenge.isQCU}}
            <Qcu
              @challenge={{@challenge}}
              @setAnswerValue={{@setAnswerValue}}
              @assessment={{@assessment}}
              @isDisabled={{@isDisabled}}
            />
          {{else if @challenge.isQCM}}
            <Qcm
              @challenge={{@challenge}}
              @setAnswerValue={{@setAnswerValue}}
              @setValidationWarning={{@setValidationWarning}}
              @assessment={{@assessment}}
              @isDisabled={{@isDisabled}}
            />
          {{/if}}
        </div>
      {{/if}}
      {{#if @challenge.autoReply}}
        <div class="challenge-content__autoreply">
          <AutoReply
            @validateAnswer={{@validateAnswer}}
            @isEmbedAutoValidated={{@challenge.isEmbedAutoValidated}}
            @setAnswerValue={{@setAnswerValue}}
          />
        </div>
      {{/if}}
      <ChallengeActions
        @validateAnswer={{@validateAnswer}}
        @skipChallenge={{@skipChallenge}}
        @level={{@activity.level}}
        @nextAction={{@resume}}
        @isLesson={{@challenge.isLesson}}
        @disableCheckButton={{@disableCheckButton}}
        @disableLessonButton={{@disableLessonButton}}
        @answerHasBeenValidated={{@answerHasBeenValidated}}
        @responseColor={{@responseColor}}
      />
    </div>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
