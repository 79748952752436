import { template } from "@ember/template-compiler";
import { action } from '@ember/object';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import didRender from '../modifiers/did-render';
const ROBOT_IMAGE_OFFSET = 52;
export default class RobotDialog extends Component {
    @tracked
    resizeObserver;
    @action
    observeResize() {
        const bubbles1 = window.document.querySelector('.bubbles');
        this.resizeObserver = new ResizeObserver(([entries1])=>{
            this.positionRobot(entries1.contentRect.height);
        });
        this.resizeObserver.observe(bubbles1);
    }
    get getRobotImageUrl() {
        return `/images/robot/dialog-robot-${this.args.class ? this.args.class : 'default'}.svg`;
    }
    positionRobot(bubblesHeight1) {
        const robotImage1 = window.document.querySelector('.robot-speaking__logo');
        if (robotImage1) {
            robotImage1.style.transition = `all 0.4s ease-in-out`;
            robotImage1.style.transform = `translateY(${bubblesHeight1 - ROBOT_IMAGE_OFFSET}px)`;
        }
    }
    static{
        template(`
    <div class="robot-speaking">
      <img class="robot-speaking__logo" alt="mascotte pix1d" src={{this.getRobotImageUrl}} />
      <div class="bubbles" {{didRender this.observeResize}}>
        {{yield}}
      </div>
    </div>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
