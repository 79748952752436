import { template } from "@ember/template-compiler";
import PixButton from '@1024pix/pix-ui/components/pix-button';
import { action } from '@ember/object';
import { htmlSafe } from '@ember/template';
import Component from '@glimmer/component';
import { t } from 'ember-intl';
import didRender from '../../../modifiers/did-render';
export default class EmbeddedSimulator extends Component {
    get embedDocumentHeightStyle() {
        const baseHeight1 = this.args.height ?? '600';
        const itemMedia1 = document.getElementsByClassName('challenge-content__media ')[0];
        const height1 = this.args.isMediaWithForm ? (baseHeight1 * itemMedia1.offsetWidth) / 710 : baseHeight1;
        return htmlSafe(`height: ${height1}px; max-height: ${baseHeight1}px`);
    }
    @action
    setIframeHtmlElement(htmlElement1) {
        this.iframe = htmlElement1;
    }
    @action
    rebootSimulator() {
        const iframe1 = this.iframe;
        const tmpSrc1 = iframe1.src;
        const loadListener1 = ()=>{
            if (iframe1.src === 'about:blank') {
                // First onload: when we reset the iframe
                iframe1.src = tmpSrc1;
            } else {
                // Second onload: when we re-assign the iframe's src to its original value
                iframe1.focus();
                iframe1.removeEventListener('load', loadListener1);
            }
        };
        iframe1.addEventListener('load', loadListener1);
        iframe1.src = 'about:blank';
    }
    static{
        template(`
    {{! template-lint-disable style-concatenation no-inline-styles }}
    <div class="challenge-embed-simulator">
      {{#if @hideSimulator}}
        <div class="challenge-embed-simulator__overlay"></div>
      {{/if}}

      <iframe
        tabindex={{if @hideSimulator "-1"}}
        class="challenge-embed-simulator__iframe"
        src="{{@url}}"
        title="{{@title}}"
        style="{{this.embedDocumentHeightStyle}}"
        {{didRender this.setIframeHtmlElement}}
      >
      </iframe>
      {{#if @shouldDisplayRebootButton}}
        <div class="reboot-container">
          <PixButton
            class="link link--grey reboot-container__content"
            aria-label={{t "pages.challenge.embed-simulator.actions.reset-label"}}
            @iconBefore="refresh"
            @variant="tertiary"
            @triggerAction={{this.rebootSimulator}}
          >
            {{t "components.challenge.embed-simulator.actions.reset"}}
          </PixButton>
        </div>
      {{/if}}
    </div>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
