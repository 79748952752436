import { template } from "@ember/template-compiler";
export default template(`
  <div class="card-wrapper">
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
