import { createCache, getValue } from '@glimmer/tracking/primitives/cache';
export { createCache, getValue } from '@glimmer/tracking/primitives/cache';
import { macroCondition, getGlobalConfig } from '@embroider/macros';
export { memoTransact, transact, untracked } from "./-private.js";
export { dependentKeyCompat as compat } from '@ember/object/compat';
function cached(target, key, descriptor) {
  // Error on `@cached()`, `@cached(...args)`, and `@cached propName = value;`
  macroCondition(getGlobalConfig().WarpDrive.env.DEBUG) ? (test => {
    if (!test) {
      throw new Error('You attempted to use @cached(), which is not necessary nor supported. Remove the parentheses and you will be good to go!');
    }
  })(target !== undefined) : {};
  macroCondition(getGlobalConfig().WarpDrive.env.DEBUG) ? (test => {
    if (!test) {
      throw new Error(`You attempted to use @cached on with ${arguments.length > 1 ? 'arguments' : 'an argument'} ( @cached(${Array.from(arguments).map(d => `'${d}'`).join(', ')}), which is not supported. Dependencies are automatically tracked, so you can just use ${'`@cached`'}`);
    }
  })(typeof target === 'object' && typeof key === 'string' && typeof descriptor === 'object' && arguments.length === 3) : {};
  macroCondition(getGlobalConfig().WarpDrive.env.DEBUG) ? (test => {
    if (!test) {
      throw new Error(`The @cached decorator must be applied to getters. '${key}' is not a getter.`);
    }
  })(typeof descriptor.get === 'function') : {};
  const caches = new WeakMap();
  // eslint-disable-next-line @typescript-eslint/unbound-method
  const getter = descriptor.get;
  descriptor.get = function () {
    if (!caches.has(this)) caches.set(this, createCache(getter.bind(this)));
    return getValue(caches.get(this));
  };
}
export { cached };