import { deprecate } from '@ember/debug';
import { macroCondition, getGlobalConfig, dependencySatisfies, importSync } from '@embroider/macros';
import { m as defaultRules, b as plural, a as singular, i as irregular, u as uncountable } from "./inflect-8aYUyMN7.js";
if (macroCondition(getGlobalConfig().WarpDrive.deprecations.DEPRECATE_EMBER_INFLECTOR)) {
  if (macroCondition(dependencySatisfies('ember-inflector', '*'))) {
    const Inflector = importSync('ember-inflector').default;
    const {
      inflector
    } = Inflector;

    // eslint-disable-next-line @typescript-eslint/unbound-method
    const originalPlural = inflector.plural;
    // eslint-disable-next-line @typescript-eslint/unbound-method
    const originalSingular = inflector.singular;
    // eslint-disable-next-line @typescript-eslint/unbound-method
    const originalIrregular = inflector.irregular;
    // eslint-disable-next-line @typescript-eslint/unbound-method
    const originalUncountable = inflector.uncountable;

    // copy over any already registered rules

    // ember-inflector mutates the default rules arrays
    // with user supplied rules, so we keep track of what
    // is default via our own list.
    const defaultPluralKeys = new Set();
    const defaultSingularKeys = new Set();
    defaultRules.plurals.forEach(([regex]) => {
      defaultPluralKeys.add(regex.toString());
    });
    defaultRules.singular.forEach(([regex]) => {
      defaultSingularKeys.add(regex.toString());
    });
    const {
      defaultRules: defaultRules$1
    } = Inflector;
    const {
      rules
    } = inflector;
    const irregularMap = new Map();
    const toIgnore = new Set();
    const uncountableSet = new Set(defaultRules$1.uncountable);
    defaultRules$1.irregularPairs.forEach(([single, plur]) => {
      irregularMap.set(single.toLowerCase(), plur);
      toIgnore.add(plur.toLowerCase());
    });
    const irregularLookups = new Map();
    Object.keys(rules.irregular).forEach(single => {
      const plur = rules.irregular[single];
      irregularLookups.set(single, plur);
    });

    // load plurals
    rules.plurals.forEach(([regex, replacement]) => {
      if (defaultPluralKeys.has(regex.toString())) {
        return;
      }
      plural(regex, replacement);
      deprecate(`WarpDrive/EmberData no longer uses ember-inflector for pluralization.\nPlease \`import { plural } from '@ember-data/request-utils/string';\` instead to register a custom pluralization rule for use with EmberData.`, false, {
        id: 'warp-drive.ember-inflector',
        until: '6.0.0',
        for: 'warp-drive',
        since: {
          enabled: '5.3.4',
          available: '5.3.4'
        },
        url: 'https://deprecations.emberjs.com/id/warp-drive.ember-inflector'
      });
    });

    // load singulars
    rules.singular.forEach(([regex, replacement]) => {
      if (defaultSingularKeys.has(regex.toString())) {
        return;
      }
      singular(regex, replacement);
      deprecate(`WarpDrive/EmberData no longer uses ember-inflector for singularization.\nPlease \`import { singular } from '@ember-data/request-utils/string';\` instead to register a custom singularization rule for use with EmberData.`, false, {
        id: 'warp-drive.ember-inflector',
        until: '6.0.0',
        for: 'warp-drive',
        since: {
          enabled: '5.3.4',
          available: '5.3.4'
        },
        url: 'https://deprecations.emberjs.com/id/warp-drive.ember-inflector'
      });
    });

    // load irregulars
    Object.keys(rules.irregular).forEach(single => {
      const plur = rules.irregular[single];
      const defaultPlur = irregularMap.get(single);
      if (defaultPlur && defaultPlur === plur) {
        return;
      }
      if (toIgnore.has(single)) {
        return;
      }
      const actualSingle = irregularLookups.get(plur.toLowerCase()) || single;
      toIgnore.add(plur.toLowerCase());
      irregular(actualSingle, plur);
      deprecate(`WarpDrive/EmberData no longer uses ember-inflector for irregular rules.\nPlease \`import { irregular } from '@ember-data/request-utils/string';\` instead to register a custom irregular rule for use with EmberData for '${actualSingle}' <=> '${plur}'.`, false, {
        id: 'warp-drive.ember-inflector',
        until: '6.0.0',
        for: 'warp-drive',
        since: {
          enabled: '5.3.4',
          available: '5.3.4'
        },
        url: 'https://deprecations.emberjs.com/id/warp-drive.ember-inflector'
      });
    });

    // load uncountables
    Object.keys(rules.uncountable).forEach(word => {
      if (uncountableSet.has(word) || rules.uncountable[word] !== true) {
        return;
      }
      uncountable(word);
      deprecate(`WarpDrive/EmberData no longer uses ember-inflector for uncountable rules.\nPlease \`import { uncountable } from '@ember-data/request-utils/string';\` instead to register a custom uncountable rule for '${word}' for use with EmberData.`, false, {
        id: 'warp-drive.ember-inflector',
        until: '6.0.0',
        for: 'warp-drive',
        since: {
          enabled: '5.3.4',
          available: '5.3.4'
        },
        url: 'https://deprecations.emberjs.com/id/warp-drive.ember-inflector'
      });
    });
    inflector.plural = function (...args) {
      plural(...args);
      deprecate(`WarpDrive/EmberData no longer uses ember-inflector for pluralization.\nPlease \`import { plural } from '@ember-data/request-utils/string';\` instead to register a custom pluralization rule for use with EmberData.`, false, {
        id: 'warp-drive.ember-inflector',
        until: '6.0.0',
        for: 'warp-drive',
        since: {
          enabled: '5.3.4',
          available: '5.3.4'
        },
        url: 'https://deprecations.emberjs.com/id/warp-drive.ember-inflector'
      });
      return originalPlural.apply(inflector, args);
    };
    inflector.singular = function (...args) {
      singular(...args);
      deprecate(`WarpDrive/EmberData no longer uses ember-inflector for singularization.\nPlease \`import { singular } from '@ember-data/request-utils/string';\` instead to register a custom singularization rule for use with EmberData.`, false, {
        id: 'warp-drive.ember-inflector',
        until: '6.0.0',
        for: 'warp-drive',
        since: {
          enabled: '5.3.4',
          available: '5.3.4'
        },
        url: 'https://deprecations.emberjs.com/id/warp-drive.ember-inflector'
      });
      return originalSingular.apply(inflector, args);
    };
    inflector.irregular = function (...args) {
      irregular(...args);
      deprecate(`WarpDrive/EmberData no longer uses ember-inflector for irregular rules.\nPlease \`import { irregular } from '@ember-data/request-utils/string';\` instead to register a custom irregular rule for use with EmberData.`, false, {
        id: 'warp-drive.ember-inflector',
        until: '6.0.0',
        for: 'warp-drive',
        since: {
          enabled: '5.3.4',
          available: '5.3.4'
        },
        url: 'https://deprecations.emberjs.com/id/warp-drive.ember-inflector'
      });
      return originalIrregular.apply(inflector, args);
    };
    inflector.uncountable = function (...args) {
      uncountable(...args);
      deprecate(`WarpDrive/EmberData no longer uses ember-inflector for uncountable rules.\nPlease \`import { uncountable } from '@ember-data/request-utils/string';\` instead to register a custom uncountable rule for use with EmberData.`, false, {
        id: 'warp-drive.ember-inflector',
        until: '6.0.0',
        for: 'warp-drive',
        since: {
          enabled: '5.3.4',
          available: '5.3.4'
        },
        url: 'https://deprecations.emberjs.com/id/warp-drive.ember-inflector'
      });
      return originalUncountable.apply(inflector, args);
    };
  }
}