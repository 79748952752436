import { warn } from '@ember/debug';
import { macroCondition, getGlobalConfig } from '@embroider/macros';
import { upgradeStore } from '@ember-data/legacy-compat/-private';
const newline = /\r?\n/;
function parseResponseHeaders(headersString) {
  const headers = Object.create(null);
  if (!headersString) {
    return headers;
  }
  const headerPairs = headersString.split(newline);
  for (let i = 0; i < headerPairs.length; i++) {
    const header = headerPairs[i];
    let j = 0;
    let foundSep = false;
    for (; j < header.length; j++) {
      if (header.charCodeAt(j) === 58 /* ':' */) {
        foundSep = true;
        break;
      }
    }
    if (foundSep === false) {
      continue;
    }
    const field = header.substring(0, j).trim();
    const value = header.substring(j + 1, header.length).trim();
    if (value) {
      const lowerCasedField = field.toLowerCase();
      headers[lowerCasedField] = value;
      headers[field] = value;
    }
  }
  return headers;
}

/**
 * A utility function that returns a promise that resolves
 * even when the source promise rejects.
 *
 * @internal
 */
function continueOnReject(promise) {
  return Promise.resolve(promise).catch(e => e);
}

/*
 * Function that always attempts to parse the response as json, and if an error is thrown,
 * returns `undefined` if the response is successful and has a status code of 204 (No Content),
 * or 205 (Reset Content) or if the request method was 'HEAD', and the plain payload otherwise.
 */
function _determineContent(response, requestData, payload) {
  let ret = payload;
  let error = null;
  if (!response.ok) {
    return payload;
  }
  const status = response.status;
  const payloadIsEmpty = payload === '' || payload === null;
  const statusIndicatesEmptyResponse = status === 204 || status === 205 || requestData.method === 'HEAD';
  if (macroCondition(getGlobalConfig().WarpDrive.env.DEBUG)) {
    if (payloadIsEmpty && !statusIndicatesEmptyResponse) {
      const message = `The server returned an empty string for ${requestData.method} ${requestData.url}, which cannot be parsed into a valid JSON. Return either null or {}.`;
      if (payload === '') {
        warn(message, {
          id: 'ds.adapter.returned-empty-string-as-JSON'
        });
      }
    }
  }
  if (response.ok && (statusIndicatesEmptyResponse || payloadIsEmpty)) {
    return;
  }
  try {
    ret = JSON.parse(payload);
  } catch (e) {
    if (!(e instanceof SyntaxError)) {
      return e;
    }
    e.payload = payload;
    error = e;
  }
  if (error) {
    if (macroCondition(getGlobalConfig().WarpDrive.env.DEBUG)) {
      // eslint-disable-next-line no-console
      console.warn('This response was unable to be parsed as json.', payload);
    }
    return error;
  }
  return ret;
}
function determineBodyPromise(response, requestData) {
  // response.text() may resolve or reject
  // it is a native promise, may not have finally
  return continueOnReject(response.text()).then(payload => _determineContent(response, requestData, payload));
}
const RBRACKET = /\[\]$/;
function isPlainObject(obj) {
  return Object.prototype.toString.call(obj) === '[object Object]';
}
function isPrimitiveArray(obj) {
  return Array.isArray(obj);
}
function isParamsArray(obj) {
  return Array.isArray(obj);
}
function buildParams(prefix, obj, s) {
  let i, len, key;
  if (prefix) {
    if (isPrimitiveArray(obj)) {
      for (i = 0, len = obj.length; i < len; i++) {
        if (RBRACKET.test(prefix)) {
          add(s, prefix, obj[i]);
        } else {
          buildParams(prefix + '[' + (typeof obj[i] === 'object' && obj[i] !== null ? i : '') + ']', obj[i], s);
        }
      }
    } else if (isPlainObject(obj)) {
      for (key in obj) {
        buildParams(prefix + '[' + key + ']', obj[key], s);
      }
    } else {
      macroCondition(getGlobalConfig().WarpDrive.env.DEBUG) ? (test => {
        if (!test) {
          throw new Error(`query params cannot be a { name, value } pair if prefix is present`);
        }
      })(obj === null || typeof obj !== 'object') : {};
      add(s, prefix, obj);
    }
  } else if (isParamsArray(obj)) {
    for (i = 0, len = obj.length; i < len; i++) {
      add(s, obj[i].name, obj[i].value);
    }
  } else {
    macroCondition(getGlobalConfig().WarpDrive.env.DEBUG) ? (test => {
      if (!test) {
        throw new Error(`query params cannot be a string if no prefix is present`);
      }
    })(typeof obj !== 'string') : {};
    macroCondition(getGlobalConfig().WarpDrive.env.DEBUG) ? (test => {
      if (!test) {
        throw new Error(`query params should not be an array if no prefix is present`);
      }
    })(!Array.isArray(obj)) : {};
    macroCondition(getGlobalConfig().WarpDrive.env.DEBUG) ? (test => {
      if (!test) {
        throw new Error(`query params should not be a { name, value } pair if no prefix is present`);
      }
    })(isPlainObject(obj)) : {};
    for (key in obj) {
      buildParams(key, obj[key], s);
    }
  }
  return s;
}

/*
 * Helper function that turns the data/body of a request into a query param string.
 * This is directly copied from jQuery.param.
 */
function serializeQueryParams(queryParamsObject) {
  return buildParams('', queryParamsObject, []).join('&');
}

/*
 * Part of the `serializeQueryParams` helper function.
 */
function add(s, k, v) {
  // Strip out keys with undefined value and replace null values with
  // empty strings (mimics jQuery.ajax)
  if (v === undefined) {
    return;
  } else if (v === null) {
    v = '';
  }
  v = typeof v === 'function' ? v() : v;
  s[s.length] = `${encodeURIComponent(k)}=${encodeURIComponent(v)}`;
}
let _fetch = null;
let REQUEST = null;
function getFetchFunction() {
  // return cached fetch function
  if (_fetch !== null) {
    return _fetch();
  }

  // grab browser native fetch if available, or global fetch if otherwise configured
  if (typeof fetch === 'function') {
    // fallback to using global fetch
    _fetch = () => fetch;

    /* global FastBoot */
    // grab fetch from node-fetch
  } else if (typeof FastBoot !== 'undefined') {
    try {
      const nodeFetch = FastBoot.require('node-fetch');
      const httpRegex = /^https?:\/\//;
      const protocolRelativeRegex = /^\/\//;

      // eslint-disable-next-line no-inner-declarations
      function parseRequest(request) {
        if (request === null) {
          throw new Error("Trying to fetch with relative url but the application hasn't finished loading FastBootInfo, see details at https://github.com/ember-cli/ember-fetch#relative-url");
        }
        // Old Prember version is not sending protocol
        const protocol = request.protocol === 'undefined:' ? 'http:' : request.protocol;
        return [request.get('host'), protocol];
      }

      // eslint-disable-next-line no-inner-declarations
      function buildAbsoluteUrl(url) {
        if (protocolRelativeRegex.test(url)) {
          const [host] = parseRequest(REQUEST);
          url = host + url;
        } else if (!httpRegex.test(url)) {
          const [host, protocol] = parseRequest(REQUEST);
          url = protocol + '//' + host + url;
        }
        return url;
      }

      // eslint-disable-next-line no-inner-declarations
      function patchedFetch(input, options) {
        if (input && typeof input === 'object' && 'href' in input) {
          const url = buildAbsoluteUrl(input.href);
          const info = Object.assign({}, input, {
            url
          });
          return nodeFetch(info, options);
        } else if (typeof input === 'string') {
          const url = buildAbsoluteUrl(input);
          return nodeFetch(url, options);
        }
        return nodeFetch(input, options);
      }
      _fetch = () => patchedFetch;
    } catch (e) {
      throw new Error(`Unable to create a compatible 'fetch' for FastBoot with node-fetch`);
    }
  }
  macroCondition(getGlobalConfig().WarpDrive.env.DEBUG) ? (test => {
    if (!test) {
      throw new Error(`Cannot find a 'fetch' global and did not detect FastBoot.`);
    }
  })(_fetch) : {};
  return _fetch();
}
function setupFastboot(fastBootRequest) {
  REQUEST = fastBootRequest;
}
function serializeIntoHash(store, modelClass, snapshot, options = {
  includeId: true
}) {
  upgradeStore(store);
  const serializer = store.serializerFor(modelClass.modelName);
  macroCondition(getGlobalConfig().WarpDrive.env.DEBUG) ? (test => {
    if (!test) {
      throw new Error(`Cannot serialize record, no serializer defined`);
    }
  })(serializer) : {};
  if (typeof serializer.serializeIntoHash === 'function') {
    const data = {};
    serializer.serializeIntoHash(data, modelClass, snapshot, options);
    return data;
  }
  return serializer.serialize(snapshot, options);
}
export { setupFastboot as a, serializeIntoHash as b, determineBodyPromise as d, getFetchFunction as g, parseResponseHeaders as p, serializeQueryParams as s };