import { getOrSetUniversal, getOrSetGlobal } from "./-private.js";
const SkipCache = getOrSetUniversal('SkipCache', Symbol.for('wd:skip-cache'));
const EnableHydration = getOrSetUniversal('EnableHydration', Symbol.for('wd:enable-hydration'));
const IS_FUTURE = getOrSetGlobal('IS_FUTURE', Symbol('IS_FUTURE'));
const STRUCTURED = getOrSetGlobal('DOC', Symbol('DOC'));

/**
 * Use these options to adjust CacheHandler behavior for a request.
 *
 * @typedoc
 */

/**
 * JavaScript's native Request class.
 *
 * EmberData provides our own typings due to incompleteness in the native typings.
 *
 * @typedoc
 */

/**
 * Extends JavaScript's native {@link Request} object with additional
 * properties specific to the RequestManager's capabilities.
 *
 * @typedoc
 */

/**
 * Immutable version of {@link RequestInfo}. This is what is passed to handlers.
 *
 * @typedoc
 */

export { EnableHydration, IS_FUTURE, STRUCTURED, SkipCache };